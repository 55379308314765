import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import classnames from 'classnames';
import moment from 'moment';
import { Link, NavLink, useLocation } from 'react-router-dom'

import localizedRoute from '../../../helpers/linkHelper';

import './header.scss';

import logos from './logos';
import candle from './candle.png';

import fbIcon from './icons/fb_icon.svg'
import instagramIcon from './icons/instagram_icon.svg'

const LANG = {
  uk: 'укр',
  en: 'eng'
}

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const location = useLocation();

  return (
    i18n.languages.sort().reverse().map((lang, index) => {
      return(
        <Link
          key={`lang-${index}`}
          to={location.pathname.replace(new RegExp(i18n.languages.join('|')), lang)}
          className={classnames('locales', {active: i18n.language == lang})}
          onClick={() => { i18n.changeLanguage(lang) }}
        >
          {LANG[lang]}
        </Link>
      );
    }).reduce((e1, e2) => [e1, <span key="lang-split" className="split">|</span>, e2])
  );
}

const Candle = () => {
  const today = moment();
  const startDate = moment(_.get(window, 'siteSettings.candleData.from'));
  const endDate = moment(_.get(window, 'siteSettings.candleData.to'));

  if (!today.isBetween(startDate, endDate))
    return null;
  
  return (
    <a href={_.get(window, 'siteSettings.candleData.url')} target="_blank">
      <img className="header__candle" alt="Candle" src={candle}/>
    </a>
  );
}

const Social = () => {
  const facebookUrl = _.get(window, 'siteSettings.facebookUrl')
  const instagramUrl = _.get(window, 'siteSettings.instagramUrl')

  return (
    <div className="social">
      {facebookUrl &&
        <a className="social__link" href={facebookUrl} target="_blank"><img src={fbIcon} /></a>}
      {instagramUrl &&
      <a className="social__link" href={instagramUrl} target="_blank"><img src={instagramIcon} /></a>}
    </div>
  );
};

const Header = () => {
  const { i18n } = useTranslation();
  const [menuIsActive, setMenuIsActive] = useState(false);
  const toggleMenu = () => setMenuIsActive(!menuIsActive);

  return (
    <header>
      <div className="header__container d-flex justify-content-between align-items-center" >
      <a role="button" className={classnames("burger-menu", {'burger-menu--active': menuIsActive})} tabIndex="0" onClick={toggleMenu}>
        <span className="burger-menu__line" />
        <span className="burger-menu__line" />
        <span className="burger-menu__line" />
      </a>
      <div className="d-flex position-relative">
        <Link onClick={() => setMenuIsActive(false)} to={localizedRoute()}>
          <img className="logo" alt="Logo" src={logos[`logoG${i18n.language}`]} />
        </Link>
        <Candle />
      </div>
      <div className={classnames("navigation__backdrop", {'navigation__backdrop--active': menuIsActive})} onClick={() => setMenuIsActive(false)} />
      <ul className={classnames("navigation d-flex", {'navigation--active': menuIsActive})}>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" exact to={localizedRoute()} onClick={() => setMenuIsActive(false)}>
            <Trans i18nKey="header.seedsOfMemory">
              <div className="nav-item__text-wrapper">Grains</div> of Truth
            </Trans>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" exact to={localizedRoute('/about')} onClick={() => setMenuIsActive(false)}>
            <Trans i18nKey="header.aboutProject">
              <div className="nav-item__text-wrapper">About</div> the Project
            </Trans>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" activeClassName="active" exact to={localizedRoute('/campaigns')} onClick={() => setMenuIsActive(false)}>
            <Trans i18nKey="header.campaigns">
              <div className="nav-item__text-wrapper">Ambassador</div> campaigns
            </Trans>
          </NavLink>
        </li>
        <Social />
      </ul>
      <div className="locales">
        <Social />
        <LanguageSwitcher />
      </div>
      </div>
    </header>
  );
}

export default Header;
