import React from 'react';
import { useTranslation } from "react-i18next";
import './search-block.scss';

const SearchInfo = React.memo(() => {
  return(
    <div className="search-info text-center col-lg-10 col-12">
      <p>*Функція пошуку на «Зернах правди» здійснюється по оцифрованому Фондом Музею Голодомору Мартирологу, 
          укладеному у 2008 році у рамках унікального державного проєкту «Національна книга пам’яті жертв Голодомору 1932-1933 років в Україні». 
          Якщо Ви виявили неточності у відомостях (іменах жертв, назвах населених пунктів тощо), просимо надіслати уточнення на пошту 
          інформаційного відділу Національного музею Голодомору-геноциду: <a href="mailto:memoholod@ukr.net" className='contact-email m-0'>memoholod@ukr.net</a></p>
      <p className='mb-3'>Якщо ж Ви бажаєте додати новий запис (нині відсутнє у базі ім’я), заповніть, будь ласка, Карту обліку жертв Голодомору </p>
      <div className='d-flex justify-content-center align-items-center'>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdRelgZ2KmTS1WAyiH-1jqcbK-VShMDZpsplDgfOURAKd4JQw/viewform" className="contact-btn font-weight-bold d-block">Карта обліку жертв Голодомору </a>
      </div>
      <p className='mt-3'>Внесені Вами дані будуть опрацьовані експертами Національного Музею Голодомору-геноциду.</p>
    </div>
  )
});

export default SearchInfo;
