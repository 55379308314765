import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const ContributionsList = ({
  contributions,
  totalPages,
  currentPage,
  setCurrentPage
}) => {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleTooltip = (e) => {
    if (e.currentTarget.scrollWidth > e.currentTarget.offsetWidth) {
      setShowTooltip(true);
    }
  };

  return (
    <>
      <table>
        <tbody>
          { contributions.map((contribution) => (
            <tr key={`contribution-${contribution.id}`}>
              <td className="date pr-0">
                <div className="date-container">
                    {moment(contribution.date).format('DD MMMM YYYY, HH:mm')}
                </div>
              </td>
              <td className="full-name">
                <div className="full-name" data-tip-disable={!showTooltip} data-tip={contribution.full_name}
                                           onMouseOver={handleTooltip} onMouseLeave={() => setShowTooltip(false)}>
                  {contribution.full_name || i18n.t('contributionsPage.anonymousDonor')}
                </div>
                { contribution.comment && (
                  <div className="comment">
                    {contribution.comment}
                  </div>
                )}
              </td>
              <td className="sum pl-0 font-weight-bold">
                <div className="sum-container">
                  {contribution.amount} {contribution.currency}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      { totalPages > 1 && (
        <ReactPaginate
          pageCount={totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          previousLabel={currentPage == 1 ? <i className="icon-arrow-disabled" /> : <i className="icon-arrow-enabled"/>}
          nextLabel={currentPage == totalPages ? <i className="icon-arrow-disabled" /> : <i className="icon-arrow-enabled"/>}
          containerClassName="pagination align-items-center"
          pageClassName="page"
          activeClassName="active"
          onPageChange={({ selected }) => setCurrentPage(selected + 1)}
        />
      )}

      <ReactTooltip key={Math.random()} />
    </>
  );
};

export default ContributionsList;
