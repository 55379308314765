import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import classNames from 'classnames'

import { percent } from '../../../util/numbers';

import './Progress.scss';

const Progress = ({ collected, goal, show_progress_bar=true, inactive=false }) => {
  const value = inactive && !show_progress_bar ? 100 : percent({ collected, goal });

  return (
    <div className={classNames("campaign-card--progress", {'campaign-card--progress--hidden': !show_progress_bar})}>
      <div className={classNames("progress-text", {'progress-text--dark': value > 60})}>{value}%</div>
      <ProgressBar now={value} />
    </div>
  );
};

export default Progress;
