import React from 'react'
import ReactMetaTags from 'react-meta-tags';
import _ from 'lodash';

import imageUk from '../../../../assets/images/og_image_uk.png'
import imageEn from '../../../../assets/images/og_image_en.png'
import { useTranslation } from 'react-i18next';

const images = {
  uk: imageUk,
  en: imageEn
}

const MetaTags = props => {
  const { i18n } = useTranslation();

  const title = _.get(props, 'title', '');
  const description = _.get(props, 'description', '');
  const ogImage = _.get(props, 'ogImage', images[i18n.language])

  return (
    <ReactMetaTags>
      <title>{`${title} | ${window.siteSettings.appTitle}`}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={`${_.get(props, 'ogTitle', title)} | ${window.siteSettings.appTitle}`} />
      <meta property="og:description" content={_.get(props, 'ogDescription', description)} />
      <meta property="og:image" content={ogImage} />
      <meta name='twitter:card' content='photo' />
      <meta name="twitter:title" content={`${_.get(props, 'ogTitle', title)} | ${window.siteSettings.appTitle}`} />
      <meta name="twitter:description" content={_.get(props, 'twitterTitle', description)} />
    </ReactMetaTags>
  )
}

export default MetaTags;
