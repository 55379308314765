import React from 'react';
import { useTranslation } from "react-i18next";

import MetaTags from '../MetaTags';
import Breadcrumbs from '../Breadcrumbs';

import './InfoPage.scss';

import metaTagsText from '../../../util/meta.json';

const ADD_INFO_LINK = 'https://docs.google.com/forms/d/e/1FAIpQLSdRelgZ2KmTS1WAyiH-1jqcbK-VShMDZpsplDgfOURAKd4JQw/viewform';

const InfoBreadcrumbs = () => {
  const { i18n } = useTranslation();
  const collection = [
    { text: i18n.t("breadcrumbs.main"), to: '/' },
    { text: i18n.t("breadcrumbs.info"), active: true}
  ];

  return (
    <div className="info-breadcrumbs">
      <Breadcrumbs collection={collection}/>
    </div>
  );
};

const InfoPage = () => {
  window.scrollTo(0, 0);

  const { i18n } = useTranslation();

  return (
    <>
      <MetaTags 
        title={metaTagsText[i18n.language].info.title}
        description={metaTagsText[i18n.language].info.description}
      />
      <div className="info-page">
        <div className="container">
          <InfoBreadcrumbs />
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="info-title">
                <h1 className="font-weight-bold">{i18n.t("infoPage.title")}</h1>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 info-content">
              <p>{i18n.t("infoPage.header")}</p>
              <p>{i18n.t("infoPage.main")}</p>
              <p>{i18n.t("infoPage.goal")}</p>
              <p>{i18n.t("infoPage.new_registry")}</p>
              <p>{i18n.t("infoPage.acknowledgement")}</p>
              <div className="add-info">
                <a target="_blank" className="add-info-btn" href={ADD_INFO_LINK}>
                  {i18n.t("infoPage.sendInfo")}
                </a>
              </div>
            </div>
          </div>
          <div className="row justify-content-center info-slogan">
            <h2>{i18n.t("infoPage.slogan")}</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoPage;
