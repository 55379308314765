import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { isEmpty } from 'lodash';
import { useTranslation, Trans } from "react-i18next";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";
import { useParams } from "react-router"
import classnames from 'classnames';
import axios from "axios";
import { sprintf } from "sprintf-js";
import moment from 'moment';
import * as clipboard from "clipboard-polyfill/text";
import ReactPlayer from 'react-player';

import ModalWindow from '../ModalWindow';
import Breadcrumbs from '../Breadcrumbs';
import { ActiveCampaignCardContent, InactiveCampaignCardContent } from '../CampaignCard/CampaignCard';
// import DonationForm from '../DonationForm';
import Spinner from '../Spinner';
import ToastMessage from '../ToastMessage';
import { formatCurrency } from '../../../util/numbers';
import MetaTags from '../MetaTags';
import NotFoundPage from '../ErrorPages/NotFoundPage';
import ContributionsModalContent from './ContributionsModalContent';

import './CampaignPage.scss';

import metaTagsText from '../../../util/meta.json'
import pluralizer from '../../../util/pluralizer';
import BankDetails from '../SeedsPage/BankDetails';


const CampaignBreadcrumbs = ({ current }) => {
  const { i18n } = useTranslation();

  const collection = [
    { text: i18n.t("breadcrumbs.main"), to: '/' },
    { text: i18n.t("breadcrumbs.campaigns"), to: '/campaigns' },
    { text: current.name, active: true }
  ];

  return (
    <div className="campaigns--breadcrumbs">
      <Breadcrumbs collection={collection} />
    </div>
  );
};

const CampaignAside = ({
  slug,
  campaign: {
    id,
    about,
    name,
    picture,
    goal,
    donations_count,
    donations_sum,
    preloaded_data,
    deadline,
    active,
    show_progress_bar
  }
}) => {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);

  const showDonateModal = () => {
    ReactDOM.render(
      <ModalWindow title={i18n.t("seedsPage.contribute")}>
        <BankDetails/>
      </ModalWindow>, document.getElementById('modal')
    );
  };

  const showContributionsModal = () => {
    ReactDOM.render(
      <ModalWindow title={i18n.t("campaignPage.allContributions", {name})} widthVariant="lg">
        <ContributionsModalContent preloadedData={preloaded_data} slug={slug}/>
      </ModalWindow>, document.getElementById('modal')
    );
  };

  const copy = () => {
    clipboard.writeText(window.location.href).then(() => {
      ReactDOM.render(<ToastMessage content={i18n.t("toast.copiedToClipboard")} delay={1500} />, document.getElementById('toast'));
    }).catch(err => {
      console.error('Fallback: unable to copy', err);
    });
  }

  let daysLeft = moment(deadline).diff(moment(new Date()), 'days');
  daysLeft = daysLeft < 0 ? 0 : daysLeft;

  return (
    <div className="campaign--aside col-12 col-md-4 col-lg-3 px-3 mb-5 px-md-0">
      <div className="campaign-card--photo mb-3">
        <img alt="" src={picture.content.url}/>
      </div>
      <div className={classnames("campaign-card--content auto-height", { inactive: !active })}>
        { active ?
          <>
            <ActiveCampaignCardContent
              donations_sum={donations_sum}
              donations_count={donations_count}
              active={active}
              goal={goal}
              showDonateModal={showDonateModal}
              show_progress_bar={show_progress_bar}
            />
            <div className={classnames("percent-of-goal", {invisible: !show_progress_bar})}>
              <Trans i18nKey="campaignPage.percentOfGoal">
                raised from goal of <b>{{goal: formatCurrency(goal)}}</b> {{currency: i18n.t("currency.uah")}}
              </Trans>
            </div>
            <div className="days-left">
              <Trans i18nKey="campaignPage.daysLeft">
                <b>{{daysLeft: daysLeft}}</b> {{days: pluralizer('campaignPage.days', daysLeft)}} left until the end of the campaign
              </Trans>
            </div>
          </>
          :
          <InactiveCampaignCardContent
            donations_sum={donations_sum}
            donations_count={donations_count}
            active={active}
            goal={goal}
            inactive
            show_progress_bar={show_progress_bar}
            showDonateModal={showDonateModal}
          />
        }
      </div>

      <button className="campaign--donate" onClick={showDonateModal}>
        {i18n.t("campaignsPage.contribute")}
      </button>

      <div className="campaign-links d-flex justify-content-center">
        <button onClick={copy} className="clear-button">
          <i className="fas fa-link"/>
        </button>
        <FacebookShareButton url={window.location}>
          <i className="fab fa-facebook-f"/>
        </FacebookShareButton>
        <TwitterShareButton url={window.location}>
          <i className="fab fa-twitter" />
        </TwitterShareButton>
        <LinkedinShareButton url={window.location}>
          <i className="fab fa-linkedin-in" />
        </LinkedinShareButton>
      </div>

      { !!preloaded_data.contributions.length && (
        <div className="campaign--aside--last-donations">
          <h3>{ i18n.t("campaignPage.lastDonations.title") } </h3>
          <table>
            <tbody>
              { preloaded_data.contributions.slice(0, 5).map((contribution, i) => {
                return (
                  <tr key={`contribution-${i}`}>
                    <td>
                      <div className="full-name">
                        { contribution.full_name }
                      </div>
                      <div className="date">
                        {moment(contribution.date).format('DD MMMM YYYY')}
                      </div>
                    </td>
                    <td className="sum pl-0 font-weight-bold">
                      {contribution.amount} {contribution.currency}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          { (preloaded_data.contributions.length > 5 || !!_.find(preloaded_data.contributions, 'comment')) && (
            <a className="campaign--aside--last-donations--open-modal" onClick={showContributionsModal} role="button">
              { i18n.t("campaignPage.lastDonations.seeList") } &#187;
            </a>
          )}
        </div>
      )}
    </div>
  );
};

const CampaignMain = ({ campaign: { name, about, short_description, video_url } }) => {
  const { i18n } = useTranslation();

  return (
    <div className="campaign--main col-12 col-md-8 col-lg-9">
      <h2 className="campaign--main--header">{name}</h2>
      <h3 className="campaign--main--description">{short_description}</h3>
      <div className="campaign--main--about white-space_pre-line">{about}</div>
      { video_url && (
        <div className="video-container">
          <ReactPlayer
            url={video_url}
            height="100%"
            width="100%"
            controls={true}
            config={{
              youtube: {
                playerVars: { showinfo: 1, hl: i18n.language, modestbranding: 1 }
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

const CampaignTopHeader = () => {
  const { i18n } = useTranslation();

  return (
    <div className="campaign--top-header-wrapper">
      <h1 className="mb-5">{i18n.t("campaignPage.header")}</h1>
    </div>
  );
};

const PreviewBanner = ({ campaign: { published } }) => {
  const { i18n } = useTranslation();
  const [hidden, setHidden] = useState(false);

  return !published && (
    <>
      <div
        className={classnames("campaign--preview-banner", { 'd-none': hidden })}
        onClick={() => setHidden(true)}
      >
        <div className="text-center w-100">
          { i18n.t("campaignPage.previewBanner") }
        </div>
      </div>
    </>
  )
};

const CampaignPage = props => {
  window.scrollTo(0, 0);

  const { i18n } = useTranslation();
  const { slug } = useParams();
  const [campaign, setCampaign] = useState({});
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    setLoading(true);

    axios.get(`/api/${i18n.language}/campaigns/${slug}`)
    .then(({ data }) => {
      setLoading(false);
      setCampaign(data);
    }).catch(({response: { status }}) => {
      setStatus(status);
      setLoading(false);
    });
  }, [i18n.language]);

  if(status == 404) return <NotFoundPage {...props} />;

  return (
    <div className="campaign--wrapper container mb-5">
      { loading ? null : <MetaTags
                            title={campaign.name}
                            description={campaign.seo_description}
                            ogTitle={sprintf(metaTagsText[i18n.language].campaign.og.title, campaign.name)}
                            ogImage={_.get(campaign, 'picture.url')}
                          /> }
      { loading && <Spinner /> }
      { !isEmpty(campaign) && (
        <>
          <PreviewBanner campaign={campaign} />
          <CampaignBreadcrumbs current={campaign} />
          <CampaignTopHeader />
          <div className="row">
            <CampaignAside campaign={campaign} slug={slug} />
            <CampaignMain campaign={campaign} />
          </div>
        </>
      )}
    </div>
  );
};

export default CampaignPage;
