import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

import ReactDOM from 'react-dom';
import React from 'react';

import './i18n';

import App from '../bundles/components/App';

document.addEventListener('DOMContentLoaded', () =>{
  ReactDOM.render(<App />, document.getElementById('root'));
});
