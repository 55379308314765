import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import axios from '../../../packs/axios';
import ReactHtmlParser from 'react-html-parser';

import MetaTags from '../MetaTags';

import './PrivacyPolicy.scss';

import metaTagsText from '../../../util/meta.json'

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();

  const [privacyPolicyHTML, setPrivacyPolicyHTML] = useState('');

  useEffect(() => {
    axios.get(`/api/${i18n.language}/privacy_policy`)
    .then(({ data }) => setPrivacyPolicyHTML(data.privacy_policy), [i18n.language]);
  })

  return (
    <div className="privacy-policy--wrapper">
      <MetaTags
        title={metaTagsText[i18n.language].privacy_policy.title} 
        ogTitle={metaTagsText[i18n.language].privacy_policy.og.title}
        description={metaTagsText[i18n.language].privacy_policy.description}
      />
      <div className="container">
        { ReactHtmlParser(privacyPolicyHTML) }
      </div>
    </div>
  );
};

export default PrivacyPolicy;
