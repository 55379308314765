import React from "react";
import _ from "lodash";

const genNum = (num, index, correlation) => num + (index < 1 ? Math.pow(index, 5) : (num / (index * correlation)));

class Grain extends React.Component {
  constructor(props) {
    super(props);

    this.svg = React.createRef();
  }

  shouldComponentUpdate(nextProps) { return !_.isEqual(nextProps, this.props); }

  componentDidMount() {
    if(this.props.autoShow) this.svg.current.parentNode.click();
  }

  render() {
    const {id, width, height, color, isActive, total, autoShow, ...rest} = this.props;
    const styles = isActive ? {position: "absolute", zIndex: 1010} : {};
    const correlation = parseInt(`1${"0".repeat(Math.floor(Math.log10(total)) + 1)}`);
    const d = _.map(this.constructor.pathMap, ([rule, ...paths]) => {
      const path = rule === "S" ? _.map(paths, (e) => genNum(e, id, correlation)) : paths;

      return `${rule} ${path.join(", ")}`;
    }).join(" ");

    return(
      <svg ref={this.svg} width={width} height={height} style={styles} viewBox={`0 0 ${width} ${height}`}>
        <path
          {...rest}
          d={d}
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          strokeWidth="1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          transform={`scale(0.14, 0.15) translate(${width}, 0)`}
          opacity="1"
        />
      </svg>
    );
  }
}

export class GrainOat extends Grain {
  static pathMap = [
    ["M", 42.7202, 2.81683],
    ["S", 37.4326, 4.83756, 25.9834, 10.6893],
    ["S", 18.1173, 26.9813, 13.2622, 42.0696],
    ["S", 10.7032, 63.0746, 10.0168, 67.5843],
    ["S", 7.88906, 81.5648, 6.63989, 89.7725],
    ["S", 8.15606, 108.204, 8.21759, 109.057],
    ["S", 14.6684, 120.94, 17.6866, 124.384],
    ["S", 28.3935, 127.816, 36.5504, 128.981],
    ["S", 44.2348, 121.354, 53.3141, 102.032],
    ["S", 53.672, 88.7408, 54.6025, 82.9026],
    ["S", 58.037, 61.3523, 61.1556, 41.7849],
    ["S", 56.8716, 16.5191, 54.6447, 13.0465],
    ["S", 52.1237, 9.11517, 51.5707, 8.25283],
    ["S", 49.2285, 3.42879, 44.7379, 2.70838],
    ["z"],
    ["M", 28.4354, 85.8951],
    ["S", 28.8309, 93.2536, 28.5091, 111.53],
    ["S", 28.2667, 121.077, 28.2576, 121.427],
    ["S", 28.258, 121.429, 28.2671, 121.08],
    ["S", 28.5735, 109.598, 29.4221, 87.5293],
    ["L", 35.4987, 44.2366],
    ["S", 36.7203, 35.4982, 39.1536, 19.8566],
    ["S", 36.9342, 27.1871, 31.7763, 50.1615],
    ["L", 28.4354, 85.8951],
    ["z"]
  ];
}

export class GrainOat2 extends Grain {
  static pathMap = [
    ["M", 30.9323, 4.38602],
    ["S", 29.2151, 4.49912, 24.7689, 6.69423],
    ["S", 15.6638, 24.4143, 9.05837, 48.8939],
    ["S", 8.05299, 68.2871, 8.33326, 96.8026],
    ["S", 12.9061, 113.089, 18.556, 121.467],
    ["S", 25.2013, 124.26, 26.2794, 124.875],
    ["S", 30.1334, 127.074, 31.9403, 128.104],
    ["S", 41.7282, 120.93, 49.3625, 110.916],
    ["S", 55.4979, 93.1634, 58.5983, 81.3174],
    ["S", 60.5001, 52.43, 59.0339, 41.7217],
    ["S", 56.9834, 34.1108, 56.6692, 32.7701],
    ["S", 55.0362, 25.8026, 53.5859, 19.6146],
    ["S", 48.4284, 14.7813, 47.1646, 13.3041],
    ["S", 43.7901, 9.3595, 41.7243, 6.94477],
    ["L", 30.9323, 4.38602],
    ["z"],
    ["M", 33.6127, 12.7316],
    ["S", 32.7504, 13.1181, 30.9406, 14.4446],
    ["S", 30.2648, 18.8302, 30.9912, 24.3059],
    ["S", 31.6318, 29.1349, 31.7529, 30.0475],
    ["S", 32.1917, 33.9023, 32.4221, 48.953],
    ["S", 32.1742, 51.5781, 32.138, 51.9517],
    ["S", 31.7081, 56.3886, 30.658, 67.2246],
    ["S", 30.833, 69.881, 30.8525, 70.2418],
    ["S", 30.9935, 72.8553, 31.1103, 75.0205],
    ["S", 32.9107, 74.4423, 33.0225, 73.8553],
    ["S", 33.3396, 72.1884, 33.4793, 71.4546],
    ["S", 34.2475, 69.0522, 34.2376, 62.89],
    ["S", 34.2233, 53.9474, 34.2181, 50.7041],
    ["S", 34.6936, 48.6703, 34.6075, 44.5366],
    ["S", 34.524, 40.5325, 34.5049, 39.6139],
    ["S", 34.5167, 38.5378, 34.4528, 37.7064],
    ["S", 34.1739, 34.0827, 33.886, 30.3417],
    ["S", 35.0536, 26.9632, 35.3783, 25.4334],
    ["S", 36.1185, 21.9459, 36.5154, 20.0762],
    ["S", 38.103, 18.5231, 39.3061, 17.1503],
    ["S", 36.8547, 13.2029, 34.6025, 12.7425],
    ["z"]
  ];
}

export class GrainOat3 extends Grain {
  static pathMap = [
    ["M", 12.7039, 66.3472],
    ["S", 12.2594, 71.0695, 11.5855, 82.3276],
    ["S", 13.5221, 98.6475, 14.809, 105.574],
    ["S", 18.3794, 111.9, 21.5776, 117.49],
    ["S", 29.2753, 123.206, 32.6757, 124.812],
    ["S", 39.0014, 121.242, 46.4265, 116.191],
    ["S", 53.5611, 96.686, 60.1623, 73.5695],
    ["S", 59.6037, 65.5926, 59.5182, 63.9991],
    ["S", 58.9497, 53.4022, 58.1619, 38.7195],
    ["S", 55.2198, 29.5922, 54.7184, 27.9234],
    ["S", 52.0368, 18.9994, 50.0241, 12.3013],
    ["S", 40.8822, 5.51292, 37.8541, 4.64235],
    ["S", 24.1707, 11.9356, 23.2913, 13.1199],
    ["S", 19.1993, 21.1049, 17.0184, 27.7532],
    ["S", 15.9998, 36.2313, 15.205, 39.93],
    ["S", 13.4473, 48.1095, 12.5035, 52.5017],
    ["S", 13.0233, 56.0201, 12.8358, 63.0768],
    ["z"],
    ["M", 29.1187, 13.3133],
    ["S", 29.0867, 13.8808, 29.0478, 14.5136],
    ["L", 29.1187, 13.3133],
    ["z"],
    ["M", 29.0021, 15.1988],
    ["S", 28.5629, 21.7873, 27.4983, 33.2186],
    ["S", 24.635, 41.9473, 24.1636, 50.1174],
    ["S", 23.4495, 62.4953, 23.1806, 67.1564],
    ["S", 21.9068, 73.3733, 22.4516, 78.2852],
    ["S", 23.1571, 84.6457, 23.3838, 86.69],
    ["S", 23.0018, 91.448, 24.5086, 98.0787],
    ["S", 26.0739, 102.781, 26.2145, 103.21],
    ["S", 27.5921, 107.416, 28.717, 110.849],
    ["L", 36.1754, 116.388],
    ["S", 34.743, 115.018, 31.4905, 111.439],
    ["S", 28.0028, 103.889, 26.2087, 98.8819],
    ["S", 24.2462, 88.6842, 24.4051, 85.359],
    ["S", 24.5961, 81.3636, 24.645, 80.3405],
    ["S", 24.6227, 75.6569, 25.1033, 68.2055],
    ["S", 26.1964, 60.4137, 26.3927, 57.2355],
    ["S", 26.6396, 53.2388, 26.7089, 52.1171],
    ["S", 27.07, 47.6187, 27.2723, 43.635],
    ["S", 27.3901, 41.313, 28.4045, 24.864],
    ["z"]
  ];
}

export class GrainWheat extends Grain {
  static pathMap = [
    ["M", 30.3358, 122.463],
    ["S", 26.48, 120.744, 17.8035, 115.666],
    ["S", 9.11943, 100.899, 1.33455, 75.2573],
    ["S", 6.44703, 50.3674, 10.7584, 32.722],
    ["S", 24.9962, 13.73, 29.3243, 10.4156],
    ["S", 43.1809, 5.54189, 53.7126, 11.6796],
    ["S", 61.8242, 25.2199, 67.1995, 42.925],
    ["S", 67.6217, 55.2805, 67.7515, 57.4149],
    ["S", 68.2328, 65.3226, 68.4984, 69.6863],
    ["S", 66.569, 79.2443, 66.2072, 81.702],
    ["S", 64.9756, 90.0691, 64.1759, 95.5018],
    ["S", 56.5117, 109.849, 53.7481, 115.022],
    ["S", 42.9943, 123.088, 34.157, 122.97],
    ["z"],
    ["M", 43.3663, 23.3007],
    ["S", 46.4007, 32.2242, 50.3871, 43.9476],
    ["S", 46.4805, 70.2193, 36.6588, 112.542],
    ["S", 36.6588, 112.542, 50.8359, 65.8964],
    ["S", 52.9177, 48.5945, 50.617, 36.624],
    ["S", 47.2317, 27.4498, 43.2503, 20.4125],
    ["S", 41.9704, 19.1955, 42.6411, 21.1681],
    ["z"]
  ];
}

export class GrainWheat2 extends Grain {
  static pathMap = [
    ["M", 68.4035, 62.5683],
    ["S", 69.7701, 55.8447, 70.9531, 38.7611],
    ["S", 57.0011, 6.03279, 51.8062, 5.66733],
    ["S", 41.741, 8.34559, 24.3297, 15.1633],
    ["S", 18.8637, 30.5319, 17.9994, 32.8563],
    ["S", 11.7669, 49.6183, 4.04604, 70.3835],
    ["S", 7.27122, 83.1439, 7.26952, 87.5111],
    ["S", 7.26408, 101.532, 7.26017, 111.595],
    ["S", 19.767, 116.428, 22.372, 118.108],
    ["S", 29.1922, 122.508, 31.9367, 124.279],
    ["S", 57.1594, 116.1, 72.217, 102.626],
    ["L", 68.4035, 62.5683],
    ["z"],
    ["M", 33.3358, 118.798],
    ["S", 33.6609, 107.157, 34.3883, 81.9749],
    ["S", 35.0829, 64.8804, 38.0464, 41.8079],
    ["S", 40.7864, 29.9368, 43.7697, 20.6227],
    ["S", 41.984, 19.9326, 35.4901, 27.603],
    ["S", 34.3117, 32.7085, 34.0356, 33.8538],
    ["S", 32.123, 41.789, 29.4313, 52.956],
    ["S", 28.4268, 67.2341, 28.4668, 85.7207],
    ["S", 29.556, 97.1622, 30.4941, 102.198],
    ["S", 32.2513, 111.632, 33.0742, 116.05],
    ["z"]
  ];
}

export class GrainWheat3 extends Grain {
  static pathMap = [
    ["M", 5.59643, 41.0955],
    ["S", 4.08144, 45.9442, 2.07294, 60.2163],
    ["S", 11.2663, 101.387, 13.0153, 106.626],
    ["S", 19.4235, 114.919, 19.5285, 115.055],
    ["S", 25.7553, 123.116, 29.7457, 128.283],
    ["S", 46.6657, 127.366, 61.6613, 114.246],
    ["S", 67.3229, 99.7563, 75.9854, 74.6723],
    ["S", 70.6041, 54.4641, 70.3047, 53.3324],
    ["S", 65.3658, 34.6632, 61.5168, 20.1134],
    ["S", 40.7345, 1.68337, 36.5255, 0.79792],
    ["S", 17.6916, 10.1406, 9.33889, 24.7173],
    ["S", 7.643, 34.3035, 6.21581, 39.1865],
    ["z"],
    ["M", 34.1832, 11.6498],
    ["S", 36.6969, 19.4645, 41.7436, 39.1251],
    ["S", 41.9041, 72.8791, 41.5774, 101.965],
    ["S", 37.7988, 115.195, 37.1152, 116.674],
    ["S", 38.5224, 114.692, 38.8994, 113.62],
    ["S", 39.3098, 112.001, 39.3867, 111.838],
    ["S", 43.0617, 104.073, 50.6445, 88.0513],
    ["S", 49.5531, 77.893, 49.4615, 73.8242],
    ["S", 49.156, 60.2591, 48.9121, 49.4308],
    ["S", 43.4386, 27.7925, 37.0197, 16.0479],
    ["z"]
  ];
}
