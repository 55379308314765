import React from 'react'
import { useTranslation } from 'react-i18next';

import localizedRoute from '../../../helpers/linkHelper';
import logos from './logos';

import './footer.scss';

export const withFooter = Component => props => <><Component {...props} /><Footer /></>

export default function Footer() {
  const { i18n } = useTranslation();

  return (
    <footer className='footer container'>
      <section className="info w-100 justify-content-center justify-content-lg-between">
        <div className="d-flex flex-column flex-md-row align-items-center">
          <a href="https://holodomor-museum-fund.org" target="_blank">
            <img alt="Holodomor Museum Fund Logo"
                 src={logos[`logoF${i18n.language}`]}
                 className="fund-logo"
            />
          </a>
          <div className="text-center text-md-left">
            <h6 className="m-0">
              {i18n.t('footer.title')}
            </h6>
            <div>{i18n.t('footer.usreou')}</div>
            <address className='address m-0'>{i18n.t('footer.address')}</address>
            <a href="mailto:info@fdhvm.org" className="contact-email m-0">info@fdhvm.org</a>
          </div>
        </div>
        <a href="https://opentech.softserveinc.com/" target="_blank" rel='nofollow'>
          <img alt="Developed by SoftServe" src={logos[`softserve_${i18n.language}`]} className="softserve-logo"/>
        </a>
      </section>
      <section className="copyright text-center">
        <span>&copy; { new Date().getFullYear() } International Charitable Foundation of the Holodomor Museum. All rights reserved</span>
      </section>
      <section className="privacy-policy">
        <a href={localizedRoute("/privacy-policy")} target="_blank">
          {i18n.t('footer.privacyPolicy')}
        </a>
        &nbsp;|&nbsp; 
        <a href={`${window.location.origin}/offer.pdf`} target="_blank">
          {i18n.t('footer.offer')}
        </a>
      </section>
    </footer>
  )
}
