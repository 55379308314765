import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from '../../../packs/axios';
import { BankDetail } from "./BankDetail";
import ReactTooltip from 'react-tooltip';

import "./bank_details.scss";

const BankDetails = () => {
  const { i18n } = useTranslation();
  const [state, setState] = useState({});

  const {bank_details_url, recipient, recipient_bank, ca_uah, ca_usd, ca_eur, paypal} = state;

  useEffect(() => {
    axios.get(`/api/${i18n.language}/bank_details`)
         .then(({data}) => setState(data));
  }, [i18n.language]);

  const accountDetails = () => {
    const accountProps = {
      recipient: recipient,
      recipientBank: recipient_bank,
      caUAH: ca_uah,
      caUSD: ca_usd,
      caEUR: ca_eur,
      payPal: paypal
    };

    let details = [];

    for (const [key, value] of Object.entries(accountProps)) {
      details.push(<BankDetail key={key} name={key} value={value} />);
    }

    return details;
  }
  return(
    <div className="row bank-details">
      {accountDetails()}

      { bank_details_url ? <div className="d-flex justify-content-center w-100">
          <a
            href={bank_details_url}
            className="custom-btn custom-btn-yellow download text-center"
          >
            <i className="icon-download" />
            {i18n.t("seedsPage.download")}
          </a>
        </div> : null }
      <ReactTooltip key={Math.random()} />
    </div>
  );

}

export default React.memo(BankDetails);
