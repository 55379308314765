import logoGuk from './uk/logo_ua2x.png';
import logoGen from './en/logo_eng2x.png';
import logoFuk from './uk/logo_fund_ua.png';
import logoFen from './en/logo_fund_en.png';
import softserve_uk from './uk/softserve_uk.svg';
import softserve_en from './en/softserve_en.svg';

export default {
  logoGuk,
  logoGen,
  logoFen,
  logoFuk,
  softserve_uk,
  softserve_en,
};
