import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import i18n from 'i18next';

import ToastMessage from '../bundles/components/ToastMessage';

const instance = axios.create();

instance.interceptors.response.use(res => res, (error) => {
  if (error.response.status >= 400 && error.response.status < 600) {
    ReactDOM.render(<ToastMessage content={i18n.t("errors.error500")} />, document.getElementById('toast'));
  }

  return Promise.reject(error);
});

export default instance;
