import React, { useState, useEffect, useMemo, Fragment } from 'react';
import axios from '../../../packs/axios';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import _ from 'lodash';

import CampaignCard from '../CampaignCard';
import Breadcrumbs from '../Breadcrumbs';
import Spinner from '../Spinner';
import MetaTags from '../MetaTags';
import localizedRoute from '../../../helpers/linkHelper';

import './CampaignsPage.scss';

import metaTagsText from '../../../util/meta.json'

const SORT_OPTIONS = [
  {sortBy: 'by_priority', key: "sortByPriority"},
  {sortBy: 'by_total_amount', key: "sortBySum"},
  {sortBy: 'by_created_at', key: "sortByDate"}
];

const CampaignsBreadcrumbs = () => {
  const { i18n } = useTranslation();
  const collection = [
    { text: i18n.t("breadcrumbs.main"), to: '/' },
    { text: i18n.t("breadcrumbs.campaigns"), active: true}
  ];

  return (
    <div className="campaigns--breadcrumbs">
      <Breadcrumbs collection={collection}/>
    </div>
  );
};

const AmbassadorRequest = () => {
  const { i18n } = useTranslation();

  return (
    <div className="campaigns--ambassador-request--button-wrapper">
      <Link to={localizedRoute('/ambassador_requests/new')} className="campaigns--ambassador-request--button">
        {i18n.t("campaignsPage.becomeAmbassador")}
      </Link>
    </div>
  );
};

const CampaignsDescription = () => {
  const { i18n } = useTranslation();

  return (
    <div className="text-content--wrapper container mt-5">
      <div className="row ">
        <div className="text-content--heading p-0 col-12 col-lg-5">
          <h1>{i18n.t("campaignsPage.joinCampaign.heading")}</h1>
        </div>
        <div className="text-content--content p-0 col-12 col-lg-7">
          <div className="pl-lg-5">
            <p className="white-space_pre-line">{i18n.t("campaignsPage.joinCampaign.description")}</p>
            <AmbassadorRequest />
          </div>
        </div>
      </div>
    </div>
  );
};

const CampaignSort = ({orderField, onSortCampaignsChange}) => {
  const {i18n} = useTranslation();
  const options = React.useMemo(() => SORT_OPTIONS.map((e) => {
    const label = i18n.t(`campaignsPage.${e.key}`);
    return <option key={`${e.key}`} value={`${e.sortBy}`}>{label}</option>;
  }), []);

  return (
    <select onChange={onSortCampaignsChange} className='campaign--sort' defaultValue={`${orderField}`}>
      {options}
    </select>
  )
}

const CampaignsHeader = ({title}) => {
  return (
    <div className="campaigns--header-wrapper">
      <h1 className="text-center m-0">{title}</h1>
    </div>
  );
};

const CampaignsGrid = ({ collection }) => {
  return (
    <div className="campaigns--grid loading row">
      { (collection.map((campaign) => {
        return (
          <div key={campaign.id} className="col-12 col-md-6 col-lg-4 col-xl-3">
            <CampaignCard campaign={campaign} />
          </div>
        );
      })) }
    </div>
  );
};

const CampaignsPage = () => {
  window.scrollTo(0, 0);

  const { i18n } = useTranslation();
  const [campaigns, setCampaigns] = useState({active: [], inactive: [], total_pages: 1});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderField, setOrderField] = useState(SORT_OPTIONS[0].sortBy);

  useEffect(() => {
    setLoading(true)
    fetchData(currentPage, orderField);
  }, [i18n.language, currentPage, orderField]);

  const fetchData = (page, field) => {
    axios.get(`/api/${i18n.language}/campaigns?`, { params: { page: page, sort: field}}).then(({ data }) => {
      const active = _.filter(data.campaigns, (c) => (c.active));
      const inactive = _.filter(data.campaigns, (c) => (!c.active));

      setCampaigns({total_pages: data.total_pages, active, inactive});
      setLoading(false);
    });
  }

  const onSortCampaignsChange = React.useCallback((e) => {
    setOrderField(e.target.value);
    setCurrentPage(1)
  }, [setOrderField]);

  return (
    <div className="campaigns--wrapper container">
      <MetaTags 
        title={metaTagsText[i18n.language].campaigns.title}
        ogTitle={metaTagsText[i18n.language].campaigns.og.title}
        description={metaTagsText[i18n.language].campaigns.description}
      />
      { loading && <Spinner /> }
      <div className='d-flex justify-content-between flex-row'>
        <CampaignsBreadcrumbs />
        <CampaignSort orderField={orderField}
                      onSortCampaignsChange={onSortCampaignsChange}/>
      </div>
      { campaigns.active.length > 0 && (
        <>
          <CampaignsHeader title={i18n.t("campaignsPage.allCampaigns")}/>
          <CampaignsGrid collection={campaigns.active} />
        </>
      )}
      { campaigns.inactive.length > 0 && (
        <>
          <CampaignsHeader title={i18n.t("campaignsPage.finishedCampaigns")} />
          <CampaignsGrid collection={campaigns.inactive} />
        </>
      )}
      { campaigns.total_pages > 1 && (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            pageCount={campaigns.total_pages}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            previousLabel={currentPage == 1 ? <i className="icon-arrow-disabled" /> : <i className="icon-arrow-enabled"/>}
            nextLabel={currentPage == campaigns.total_pages ? <i className="icon-arrow-disabled" /> : <i className="icon-arrow-enabled"/>}
            containerClassName="pagination align-items-center"
            pageClassName="page"
            activeClassName="active"
            onPageChange={({ selected }) => setCurrentPage(selected+1)}
            forcePage={currentPage-1}
          />
        </div>
      )}

      { !loading && <CampaignsDescription /> }
    </div>
  );
};

export default CampaignsPage;