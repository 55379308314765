import React from "react";
import ReactDOM from "react-dom";
import {useLocation} from "react-router-dom";
import {FacebookShareButton, TwitterShareButton} from "react-share";

import ModalWindow from "../ModalWindow";
import Spinner from "./../Spinner";

import "./tooltip.scss";
import BankDetails from "../SeedsPage/BankDetails";

const SHARE_QUERY_PARAM = "_share";
const isShared = (location) => !!location.search.split("&").find((e) => e === SHARE_QUERY_PARAM);

const ShareActions = ({i18n}) => {
  const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#000000",
    width: "100%",
    height: "40px",
    border: "1px solid #F8D37E",
    boxSizing: "border-box",
    borderRadius: "90px",
  };
  const location = useLocation();
  const query = location.search;

  if(isShared(location)) return null;

  const shareURI = `${window.location.protocol}//${window.location.host}/${i18n.language}/${query}&${SHARE_QUERY_PARAM}`;

  return(
    <div className="sharable-actions">
      <FacebookShareButton url={shareURI} style={styles}>
        <i className="fab fa-facebook-f"/> <span>{i18n.t("seedsPage.share")}</span>
      </FacebookShareButton>

      <TwitterShareButton url={shareURI} style={styles}>
        <i className="fab fa-twitter" /> <span>{i18n.t("seedsPage.share")}</span>
      </TwitterShareButton>
    </div>
  );
};

export default class Tooltip extends React.Component {
  onContributeClick = () => {
    const {i18n} = this.props;

    this.props.onClose();

    ReactDOM.render((
        <ModalWindow title={i18n.t("seedsPage.contribute")}>
          <BankDetails/>
        </ModalWindow>
      ),
      document.getElementById("modal")
    );
  };
  positionTooltip = () => {
    if(!this.container.current) return;

    this.container.current.classList.remove("bottom-left", "bottom-right", "top-right", "top-left");

    // mobile view suppress other views;
    // !!! this depends on layout
    if(document.getElementById('root').clientWidth <= 768 || window.innerHeight <= (this.props.grain_id === _.get(this.props, "victim.id", 0) ? 992 : 768)) {
      // this.container.current.style.left = 0;
      // this.container.current.style.bottom = 0;
      this.container.current.style.right = '50%';
      this.container.current.style.top = '50%';
      this.container.current.style.margin = "0 auto";
      // this.container.current.style.display = "flex";
      // this.container.current.style.justifyContent = "center";
      // this.container.current.style.alignItems = "center";
      this.container.current.style.transform = "translate(50%, -50%)";

      return;
    }

    const data = this.props.nodeRect;
    const hVertical = window.innerHeight / 2;
    const wVertical = window.innerWidth / 2;

    if(data.top <= hVertical && data.left >= wVertical) {
      // I square;
      this.container.current.classList.add("bottom-left");
      this.container.current.style.left = `${data.left - data.width + 10}px`;
      this.container.current.style.top = `${data.top + data.height / 2}px`;

      return;
    }

    if(data.top <= hVertical && data.left < wVertical) {
      // II square;
      this.container.current.classList.add("bottom-right");
      this.container.current.style.left = `${data.left + data.width + 10}px`;
      this.container.current.style.top = `${data.top + data.height / 2}px`;

      return;
    }

    if(data.top > hVertical && data.left < wVertical) {
      // III square;
      this.container.current.classList.add("top-right");
      this.container.current.style.left = `${data.left + data.width + 5}px`;
      this.container.current.style.bottom = `${window.innerHeight - data.bottom + data.height / 2}px`;

      return;
    }

    // IV square;
    this.container.current.classList.add("top-left");
    this.container.current.style.left = `${data.left - data.width + 10}px`;
    this.container.current.style.bottom = `${window.innerHeight - data.bottom + data.height / 2}px`;
  };

  constructor(props) {
    super(props);

    this.container = React.createRef();

    this.positionTooltip = this.positionTooltip.bind(this);
    this.onContributeClick = this.onContributeClick.bind(this);
  }

  componentDidMount() { this.positionTooltip(); }
  componentDidUpdate() { this.positionTooltip(); }

  render() {
    const {i18n, loading, grain_id} = this.props;

    if(loading) return(<Spinner height={30} width={30} timeout={0} htmlStyle={{zIndex: 1100}} />);

    const isRevealed = this.props.seed.toString().indexOf(".") < 0;

    return(
      <div ref={this.container} className="grainy-tooltip">
        {
          isRevealed &&
          <div className="grain-tooltip known">
            <div className="row victim-heading">
              <div className="col-10">
                {
                  grain_id === _.get(this.props, "victim.id", 0) && !isShared(this.props.location) &&
                  <div className="donation-title">
                    {i18n.t("seedsPage.donationCongrats")}
                    <br />
                    {i18n.t("seedsPage.donationVictimReveal")}
                  </div>
                }

                <div className="heading-title">{i18n.t("seedsPage.tooltipVictimTitle")}:</div>

                <h6>{_.get(this.props, "victim.last_name", null)}</h6>
                <h6>{_.get(this.props, "victim.first_name", null)}</h6>
                <h6>{_.get(this.props, "victim.surname", null)}</h6>
              </div>
              <div className="col-2">
                <a className="close" onClick={this.props.onClose} role="button">
                  <span />
                  <span />
                </a>
              </div>
            </div>

            <div className="victim-info-label">
              {i18n.t("seedsPage.tooltipVictimInfo")}
              <br />
              {i18n.t("seedsPage.tooltipInfoDescr")}:
            </div>

            <div className="victim-info">{_.get(this.props, "victim.display_information", null)}</div>

            <div className="victim-location">
              <span>{_.upperFirst(i18n.t("seedsPage.location"))}:</span>
              <br />
              {_.get(this.props, "victim.settlement", null)}, {_.get(this.props, "victim.region", null)} {i18n.t("seedsPage.region")}
            </div>

            {grain_id === _.get(this.props, "victim.id", 0) && <ShareActions {...this.props} />}
          </div>
        }
        {
          !isRevealed &&
          <div className="grain-tooltip">
            <div className="header-actions">
              <a className="close" onClick={this.props.onClose} role="button">
                <span />
                <span />
              </a>
            </div>

            <div className="contribute-details">{i18n.t("seedsPage.tooltipContributeText")}</div>

            <div className="contribute-actions">
              <button className="btn-contribute" onClick={this.onContributeClick}>{i18n.t("seedsPage.contribute")}</button>
            </div>
          </div>
        }
      </div>
    );
  }
}
