import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import axios from '../../../packs/axios';
import ReactPlayer from 'react-player';

import Map from '../App/Map';
import MetaTags from '../MetaTags';
import Partners from '../Partners/Partners';

import aboutHeaderImage from './about-header.png';

import metaTagsText from '../../../util/meta.json'

import './AboutPage.scss';

const AboutTopImage = () => {
  return (
    <div className="about--header-image--container mb-5">
      <img alt="header image" src={aboutHeaderImage}/>
    </div>
  );
};

const AboutTopVideo = ({video_url}) => {
  const { i18n } = useTranslation();

  return (
    <div className="player-wrapper mb-5">
      <div className="hidden-layer" />
      <ReactPlayer
        url={video_url}
        playing
        loop={true}
        controls={false}
        onEnded={() => {}}
        muted
        volume={0}
        width='100%'
        height='100%'
        className="react-player"
        config={{
          youtube: {
            playerVars: { showinfo: 1, hl: i18n.language }
          }
        }}
      />
    </div>
  )
}

const AboutDescription = () => {
  const { i18n } = useTranslation();

  return (
    <div className="text-content--wrapper mb-5 container">
      <div className="row">
        <div className="text-content--heading text-center col-12 mb-3">
          <h1 className="mission font-weight-normal">{i18n.t("aboutPage.heading.mission")}</h1>
        </div>
      </div>

      <div className="row">
        <div className="text-content--heading col-12 col-md-6">
          <h1>{i18n.t("aboutPage.heading.reasonForMeseum")}</h1>
        </div>
        <div className="text-content--content col-12 col-md-6">
          <p>
            {i18n.t("aboutPage.content.reasonForMeseum")}
          </p>
        </div>
      </div>
  
      <div className="row">
        <div className="text-content--heading col-12 col-md-6">
          <h1 className="white-space_pre-line">{i18n.t("aboutPage.heading.museumCreation")}</h1>
        </div>
        <div className="text-content--content col-12 col-md-6">
          <p>
            {i18n.t("aboutPage.content.museumCreation")}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="text-content--heading col-12 col-md-6">
          <h1 className="white-space_pre-line">{i18n.t("aboutPage.heading.crowdfundingPlatform")}</h1>
        </div>
        <div className="text-content--content col-12 col-md-6">
          <p>
            {i18n.t("aboutPage.content.crowdfundingPlatform")}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="text-content--heading col-12 col-md-6">
          <h1>{i18n.t("aboutPage.heading.yourDonation")}</h1>
        </div>
        <div className="text-content--content col-12 col-md-6">
          <p>
            {i18n.t("aboutPage.content.yourDonation")}
          </p>
        </div>
      </div>
    </div>
  );
};

const LearnMore = ({project_website_url}) => {
  const { i18n } = useTranslation();

  if(!project_website_url) return null;

  return (
    <div className="container">
      <div className='row text-content--wrapper'>
        <div className="col-md-12 col-lg-8 text-content--content container justify-content-center">
          <p className="text-md-center">
            <Trans i18nKey="aboutPage.learnMore">
              Якщо Ви хочете <b>дізнатися більше</b> про створення Музею Голодомору (його історію, архітектурний проект, концепцію експозиції, прогрес будівництва,  інші можливості для благодійників тощо) відвідайте сайт Проекту."
            </Trans>
          </p>
          <div className="visit-website__section">
            <a href={project_website_url} className="visit-website__btn" target="_blank">
              {i18n.t('aboutPage.visitWebsite')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const AboutPage = () => {
  window.scrollTo(0, 0);

  const { i18n } = useTranslation();

  const [state, setState] = useState({});
  const [loading, setLoading] = useState(true);

  const {video_url, project_website_url} = state;

  useEffect(() => {
    setLoading(true);

    axios.get(`/api/${i18n.language}/about`)
         .then(({data}) => {
           setLoading(false);
           setState(data);
          });
  }, [i18n.language]);

  return (
    <div className="about--wrapper">
      <MetaTags 
        title={metaTagsText[i18n.language].about.title}
        ogTitle={metaTagsText[i18n.language].about.og.title}
        description={metaTagsText[i18n.language].about.description}
      />
      {video_url &&  <AboutTopVideo video_url={video_url} />}
      {!video_url && !loading && <AboutTopImage />}
      <AboutDescription />
      <LearnMore project_website_url={project_website_url} />
      <Map headingClasses="col-md-6 mb-5" />
      <Partners />
    </div>
  );
};

export default AboutPage;
