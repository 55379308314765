import i18next from 'i18next';
import pluralize from 'pluralize';
import _ from 'lodash';

export const pluralizer = (translation, number) => {
  if (i18next.language == 'en') {
    return pluralize(i18next.t(translation), number);
  }

  const formattedNumber = parseInt(_.takeRight(number.toString().split(''), 2).join(''));
  number = formattedNumber >= 10 && formattedNumber < 21 ? formattedNumber : parseInt(_.takeRight(formattedNumber.toString().split(''), 1));

  switch(true) {
    case (number == 1):
      return i18next.t(`${translation}.one`);
    case (number > 1 && number < 5):
      return i18next.t(`${translation}.few`);
    default:
      return i18next.t(`${translation}.many`);
  }
}

export default pluralizer;
