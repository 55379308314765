import React, { useState, useEffect } from "react";
import MapGl, { Source, Layer } from "react-map-gl";
import { useTranslation } from "react-i18next";
import axios from '../../../packs/axios';

import './map.scss';

const ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN;

const HEATMAP_PAINT_PROPS = {
  maxzoom: 15,
  type: 'heatmap',
  paint: {
    'heatmap-weight': {
      property: 'points_count',
      type: 'exponential',
      stops: [
        [1, 0],
        [62, 1]
      ]
    },
    'heatmap-intensity': {
      stops: [
        [11, 1],
        [15, 3]
      ]
    },
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0, 'rgba(242,242,242,0)',
      0.2, 'rgb(242,239,189)',
      0.4, 'rgb(242,236,145)',
      0.6, 'rgb(242,226,5)',
      0.8, 'rgb(242,206,71)'
    ],
    'heatmap-radius': 9,
    'heatmap-opacity': {
      default: 1,
      stops: [
        [14, 1],
        [15, 0]
      ]
    },
  }
};

const POINTS_PROPS = {
  minzoom: 14,
  type: 'circle',
  paint: {
    'circle-radius': {
      property: 'points_count',
      type: 'exponential',
      stops: [
        [{ zoom: 15, value: 1 }, 5],
        [{ zoom: 15, value: 62 }, 10],
        [{ zoom: 22, value: 1 }, 20],
        [{ zoom: 22, value: 62 }, 50],
      ]
    },
    'circle-color': 'rgb(242,206,71)',
    'circle-stroke-color': 'white',
    'circle-stroke-width': 1,
    'circle-opacity': {
      stops: [
        [14, 0],
        [15, 1]
      ]
    }
  }
};

const SINGLE_POINT_PROPS = {
  type: 'circle',
  paint: {
    'circle-radius': 5,
    'circle-color': 'rgb(255,174,66)',
    'circle-stroke-color': 'white',
    'circle-stroke-width': 1,
  }
};

const Map = ({headingClasses = ""}) => {
  const { i18n } = useTranslation();
  const [fileUrl, setFileUrl] = useState();
  const [viewport, setViewport] = useState({
    latitude: 49.5023,
    longitude: 31.6864,
    zoom: 4,
    minZoom: 3,
    maxZoom: 8
  });

  useEffect(() => {
    axios.get('/api/map/cached_data_url')
         .then(({data}) => setFileUrl(data.file_url));
  }, []);

  return(
    <div className="mapbox-wrapper">
      <div className="container">
        <div className="row">
          <h1 className={`map--heading col-12 ${headingClasses}`}>{i18n.t("aboutPage.heading.geoProfile")}</h1>
        </div>
      </div>
      <MapGl
        {...viewport}
        width="100%"
        height="400px"
        mapStyle="mapbox://styles/mapbox/dark-v10"
        onViewportChange={setViewport}
        mapboxApiAccessToken={ACCESS_TOKEN}
      >
        <Source type="geojson" data='/api/map.json'>
          <Layer {...HEATMAP_PAINT_PROPS} />
          <Layer {...POINTS_PROPS} />
        </Source>
        { fileUrl &&
          <Source type="geojson" data={fileUrl}>
            <Layer {...HEATMAP_PAINT_PROPS} />
            <Layer {...POINTS_PROPS} />
          </Source>
        }
      </MapGl>
    </div>
  );
}

export default Map;
