import React from 'react';
import classNames from 'classnames';
import closeIcon from '../App/icons/close.svg';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { keyword: '' }
    this.input = React.createRef();

    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
  }

  focus() { if(this.input.current) this.input.current.focus(); }
  blur() { if(this.input.current) this.input.current.blur(); }

  render() {
    return(
      <>
      <input type="text"
             ref={this.input}
             value={this.state.keyword}
             className={classNames({"chrome-support": !!window.chrome})}
             placeholder={this.props.placeholder}
             onChange={(e) => {this.setState({keyword: e.target.value})}}
             onClick={this.props.onClick}
             onBlur={this.props.onBlur}/>
      {!_.isEmpty(this.state.keyword) && <div className="close-panel d-flex justify-content-center" role="button" onClick={this.props.onClickReset} >
        <img src={closeIcon} alt="Close"/>
      </div>}
      </>
    );
  }
}
