import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../../packs/axios';
import Spinner from '../Spinner';
import ContributionsList from '../ContributionsList';

const ContributionsModalContent = ({ preloadedData: { total_pages, contributions }, slug }) => {
  const { i18n } = useTranslation();
  const [data, setData] = useState({ total_pages, contributions });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData(currentPage)

    return () => {
      setLoading(true);
    };
  }, [currentPage]);

  const fetchData = (page = 1) => {
    axios.get(`/api/campaigns/${slug}/contributions?page=${page}`)
    .then(({ data }) => {
      setLoading(false);
      setData(data);
    });
  };

  return (
    <div className="contributions-page pt-0">
      { loading && <Spinner timeout={2000}/> }
      { !!data.contributions.length && (
        <>
          <div className="d-flex align-items-center flex-column">
            <ContributionsList
              contributions={data.contributions}
              totalPages={data.total_pages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ContributionsModalContent;
