import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import './CurrencyPicker.scss'

const CURRENCIES_SYMBOLS = {
  "USD": "$",
  "EUR": "€"
}

const CURRENCIES_BY_LANG = {
  "uk": "UAH",
  "en": "USD"
}

const SUMS = {
  "UAH": [[100, 300], [500, 1000]],
  "USD": [[10, 25], [50, 100]],
  "EUR": [[10, 25], [50, 100]]
}

const radioButtonValues = (currency) => {
  const { i18n } = useTranslation();

  const values = [
    // ...SUMS[currency].map((value) => {
    //   return value.map((value) => {
    //     return { value, label: currency == 'UAH' ? `${value} ${i18n.t("currency.uah")}` : `${CURRENCIES_SYMBOLS[currency]}${value}` }
    //   });
    // }),
    [
      // { value: 'another', label: i18n.t("seedsPage.donationTable.anotherSum") },
      { value: 'detail', label: i18n.t("seedsPage.donationTable.details") }
    ]
  ];

  return values;
}

const SAVED_CURRENCY_KEY = 'savedCurrency';

const CurrencyPicker = ({onChange}) => {
  const { i18n } = useTranslation();
  const savedCurrency = useMemo(() => localStorage.getItem(SAVED_CURRENCY_KEY), []);
  const [currency, setCurrency] = useState(savedCurrency ? savedCurrency : CURRENCIES_BY_LANG[i18n.language]);
  const [checked, setChecked] = useState();

  useEffect(() => {
    setChecked(SUMS[currency][0][1]);
    onChange({currency, checked});
  }, [currency]);

  useEffect(() => {
    onChange({currency, checked});
  }, [checked]);

  const switchCurrency = useCallback((cur) => {
    setCurrency(cur);
    localStorage.setItem(SAVED_CURRENCY_KEY, cur);
  }, [])

  const radioButtons = radioButtonValues(currency).map((values, index) => {
    return(
      <tr key={index}>
        {
          values.map((object) => {
            return(
              <td key={object.value} className={classnames({active: true})} >
                <button
                  className={classnames("radio-button-wrapper", {checked: true} )}
                  onClick={() => setChecked(object.value)}
                >
                  <span className="checkmark"></span>
                  {object.label}
                </button>
              </td>
            );
          })
        }
      </tr>
    );
  });

  return (
    <>
      {/* <div className="select-currency d-flex flex-row">
        {i18n.t('seedsPage.selectCurrency')}
        <div className="currencies">
          <button
            className={classnames ("clear-button", { active: currency == 'UAH'})}
            onClick={() => {switchCurrency("UAH")}}
            disabled={currency == 'UAH'}
          >
            uah
          </button>
          &nbsp;|&nbsp;
          <button
            className={classnames ("clear-button", { active: currency == 'USD'})}
            onClick={() => {switchCurrency("USD")}}
            disabled={currency == 'USD'}
          >
            usd
          </button>
          &nbsp;|&nbsp;
          <button
            className={classnames ("clear-button", { active: currency == 'EUR'})}
            onClick={() => {switchCurrency("EUR")}}
            disabled={currency == 'EUR'}
          >
            eur
          </button>
        </div>
      </div> */}
      {/* <table className="donations-table">
        <tbody>
          { radioButtons }
        </tbody>
      </table> */}
    </>
  );
}

export default CurrencyPicker;
