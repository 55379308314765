import React, { useState, useEffect } from 'react';
import noScroll from 'no-scroll';
import * as Loaders from "react-spinners";

import './Spinner.scss'

const Spinner = ({ size, color, margin, type = "BeatLoader", timeout = 700, wrapperClass = "backdrop", htmlStyle}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const Loader = Loaders[type];

  useEffect(() => {
    const timer = setTimeout(() => {
      noScroll.on();
      setShowSpinner(true)
    }, timeout);

    return () => {
      noScroll.off();
      clearTimeout(timer);
    }
  });

  return showSpinner && (
    <div className={wrapperClass} style={{...htmlStyle}}>
      <div className="custom-spinner">
        <Loader
          color={color || "#FFCE47"}
          size={size || 50}
          margin={margin || 10}
        />
      </div>
    </div>
  );
};

export default Spinner;
