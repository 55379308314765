import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import CurrencyPicker from '../SeedsPage/CurrencyPicker';

import BankDetails from '../SeedsPage/BankDetails';
// import DonationForm from '../DonationForm';

import './MobileDonationForm.scss';

const MobileDonationForm = () => {
  const { i18n } = useTranslation();
  const [{checked, currency}, setCurrencyState] = useState({checked: '', currency: ''});

  return (
    <div className="mobile-donation-form">
      <div className="w-100">
        <BankDetails />
      </div>
      {/* <div className={classnames("mobile-donation-form__stage", {"mobile-donation-form__stage--active": stage2})}>
        { stage2 ? 
          ( checked == "detail" ?
              <BankDetails /> : 
              <DonationForm
                donationSum={checked}
                currency={currency}
              />
          ) : null
        }
      </div> */}
    </div>
  );
}

export default MobileDonationForm;
