import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detectBrowserLanguage from "detect-browser-language";
import numeral from 'numeral';
import { matchPath } from "react-router";
import _ from 'lodash';

import translationUK from './locales/uk.json';
import translationEN from './locales/en.json';
import history from "./history";

const resources = {
  uk: {
    translation: translationUK
  },
  en: {
    translation: translationEN
  },
}

let language = _.get(
                matchPath(
                  history.location.pathname, {
                  path: '/:locale(uk|en)'
                }), 'params.locale' , ''
              );

if (!language)
  language = localStorage.getItem('i18nLanguage');

if (!language) {
  // const lng = detectBrowserLanguage();
  // language = lng == 'uk' || lng == 'ru' ? 'uk' : 'en';
  language = 'uk';
}

numeral.register('locale', 'uk', {
  delimiters: {
    thousands: ' '
  }
});

numeral.locale('uk');

i18n
  .use(initReactI18next)
  .init({
    resources: resources,
    lng: language,
    fallbackLng: ["uk", "en"],
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'fancyNumber')
          return numeral(value).format('0,0');
        return value;
      }
    }
  });

  i18n.on('languageChanged' , language => localStorage.setItem('i18nLanguage', language));
