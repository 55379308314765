import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import classnames from 'classnames'

// import DonationForm from '../DonationForm';
import ModalWindow from '../ModalWindow';
import { formatCurrency } from '../../../util/numbers';
import localizedRoute from '../../../helpers/linkHelper';
import Progress from './Progress';

import './CampaignCard.scss';
import BankDetails from '../SeedsPage/BankDetails';

const CampaignCardCounts = ({
  donations_sum,
  donations_count,
  goal,
  active,
  show_progress_bar,
}) => {
  const { i18n } = useTranslation();

  return(
    <>
      <div className="campaign-card--labels">
        <div className="campaign-card--label">
          {i18n.t("campaignsPage.card.collected")}
        </div>
        <div className={classnames("campaign-card--label", {invisible: !show_progress_bar && !active})}>
          {i18n.t(`campaignsPage.card.${active ? 'honored' : 'goal'}`)}
        </div>
      </div>

      <div className="campaign-card--counts">
        <div className="campaign-card--collected-count">
          {formatCurrency(donations_sum, i18n.t("currency.uah"))}
        </div>
        {
          active ?
          <div className="campaign-card--honor-count">
            {donations_count}
          </div> :
          <div className={classnames("campaign-card--goal-sum", {invisible: !show_progress_bar})}>
          {formatCurrency(goal, i18n.t("currency.uah"))}
          </div>
        }
      </div>
    </>
  );
}

export const ActiveCampaignCardContent = ({
  donations_sum,
  donations_count,
  goal,
  active,
  show_progress_bar,
  showDonateModal,
}) => {
  const { i18n } = useTranslation();

  return(
    <>
      <CampaignCardCounts
        donations_sum={donations_sum}
        donations_count={donations_count}
        active={active}
        goal={goal}
      />
      <Progress collected={donations_sum} goal={goal} show_progress_bar={show_progress_bar}/>
    </>
  );
}

export const InactiveCampaignCardContent = ({
  donations_count,
  goal,
  show_progress_bar,
  donations_sum,
  active,
}) => {
  const { i18n } = useTranslation();

  return (
    <>
      <div className="campaign-card--honor-count text-center w-100">
        {donations_count}
      </div>
      <div className="campaign-card--label honored text-center w-100">
        {i18n.t("campaignsPage.card.honored")}
      </div>
      <Progress
        collected={donations_sum}
        goal={goal}
        show_progress_bar={show_progress_bar}
        inactive
      />
      <CampaignCardCounts
        donations_sum={donations_sum}
        donations_count={donations_count}
        show_progress_bar={show_progress_bar}
        active={active}
        goal={goal}
      />
    </>
  );
}

const CampaignCard = ({
  campaign: {
    id,
    name,
    about,
    short_description,
    picture,
    goal,
    donations_count,
    slug,
    donations_sum,
    active,
    show_progress_bar,
  },
}) => {
  const { i18n } = useTranslation();

  const showDonateModal = (e) => {
    e.preventDefault();

    ReactDOM.render(
      <ModalWindow title={i18n.t("seedsPage.contribute")}>
        <BankDetails/>
      </ModalWindow>, document.getElementById('modal'));
  };

  return (
    <div className={classnames("campaign-card--wrapper", {active: active, inactive: !active})}>
      <Link to={localizedRoute(`/campaigns/${slug}`)}>
        <div className="campaign-card--photo">
          <img alt="campaign image" src={picture.content.url}/>
        </div>
        <div className="campaign-card--content">
          <h2 className="campaign-card--title">
            {name}
          </h2>
          <h3 className="campaign-card--subtitle">
            {short_description}
          </h3>


          { active ?
            <>
              <ActiveCampaignCardContent
                donations_sum={donations_sum}
                donations_count={donations_count}
                active={active}
                goal={goal}
                show_progress_bar={show_progress_bar}
                showDonateModal={showDonateModal}
              />
              <div className="campaign-card--donate-wrapper">
                <button className="campaign-card--donate" onClick={showDonateModal}>
                  {i18n.t("campaignsPage.contribute")}
                </button>
              </div>
            </>
            :
            <InactiveCampaignCardContent
              donations_sum={donations_sum}
              donations_count={donations_count}
              active={active}
              goal={goal}
              show_progress_bar={show_progress_bar}
              showDonateModal={showDonateModal}
            />
          }
        </div>
      </Link>
    </div>
  );
};

export default CampaignCard;
