import React from 'react';
import { useTranslation } from "react-i18next";

import MetaTags from '../MetaTags';

import './NotFoundPage.scss';

import metaTagsText from '../../../util/meta.json'


const NotFoundPage = () => {
  const { i18n } = useTranslation();

  return (
    <div className="error-wrapper flex-1">
      <MetaTags 
        title={metaTagsText[i18n.language].not_found.title}
      />
      <h1>{i18n.t('errors.error404')}</h1>
      <h2>{i18n.t('errors.pageNotFound')}</h2>
    </div>
  );
};

export default NotFoundPage;
