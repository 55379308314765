import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { useTranslation } from "react-i18next";

import CurrencyPicker from './CurrencyPicker';
// import DonationForm from '../DonationForm';
import MobileDonationForm from '../MobileDonationForm';
import ModalWindow from '../ModalWindow';
import BankDetails from './BankDetails';
import localizedRoute from '../../../helpers/linkHelper';

const Donation = React.memo((props) => {
  const { i18n } = useTranslation();
  const [{checked, currency}, setCurrencyState] = useState({checked: '', currency: ''});

  const showDonateModal = () => {
    if (document.getElementById('root').clientWidth <= 1149) {
      ReactDOM.render(
        <ModalWindow title={i18n.t(`seedsPage.contribute`)}>
          <MobileDonationForm />
        </ModalWindow>, document.getElementById('modal')
      );
    } else {
      ReactDOM.render(
        <ModalWindow title={i18n.t(`seedsPage.bankDetails`)}>
        {/* <ModalWindow title={i18n.t(`seedsPage.${checked == "detail" ? "bankDetails" : "contribute"}`)}> */}
          <BankDetails />
          {/* { checked == "detail" ? <BankDetails /> : <DonationForm
                                                      donationSum={checked}
                                                      currency={currency}
                                                    /> } */}
        </ModalWindow>, document.getElementById('modal')
      );
    }
  };
  const currentCurrency = i18n.language === 'uk' ? 'uah' : 'usd';
  return(
    <>
      <div className="donation d-flex flex-column align-items-center donation-search--active">
        <CurrencyPicker onChange={setCurrencyState} />
        <button className="donate custom-btn" onClick={showDonateModal}>
          {i18n.t("seedsPage.contribute")}
        </button>
      </div>
      {
        props.donations_count > 0 && <div className='donation-info d-flex flex-column align-items-center'>
          <div className="honored-names text-center">
            <div className="number">{props.donations_count}</div>
            {i18n.t("seedsPage.honoredNames")}
          </div>
          <div className="total-sum text-center">
            <div className="number">{numeral(props[`total_sum_${currentCurrency}`]).format('0,0')}</div>
            {i18n.t("seedsPage.collectedSum", {currency: i18n.t(`currency.${currentCurrency}`)})}
          </div>
          <Link to={localizedRoute('/contributions')} className="donations">
            {i18n.t("seedsPage.viewListOfContributions")}
          </Link>
        </div>
      }
    </>
  );
});

export default Donation;
