import React, { useEffect } from 'react'
import ReactDOM from 'react-dom';

import './toast_message.scss'

const ToastMessage = ({content, delay: propsDelay}) => {
  const delay = typeof propsDelay === 'number' ? propsDelay : 4000

  const closeToast = () => ReactDOM.unmountComponentAtNode(document.getElementById('toast'));

  useEffect(() => {
    let timeout = setTimeout(closeToast, delay)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div style={{animationDuration: `${(delay)}ms`}} className="toast-message__container">
      {content}
      <button className='toast-message__close-button ml-3' onClick={closeToast}/>
    </div>
  );
};

export default ToastMessage;
