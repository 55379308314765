import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import axios from '../../../packs/axios';
import { useTranslation } from 'react-i18next';

import './Partners.scss';

const NextArrow = props => {
  const { className, style, onClick } = props;

  return (
    <button
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg width="28" height="50" viewBox="0 0 28 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2L25 25L2 48" strokeWidth="3" strokeLinecap="round"/>
      </svg> 
    </button>
  );
}

const PrevArrow = props => {
  const { className, style, onClick } = props;

  return (
    <button
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg width="27" height="50" viewBox="0 0 27 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25 2L3 25L25 48" strokeWidth="3" strokeLinecap="round"/>
      </svg>
    </button>
  );
}             

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const i18n = useTranslation();

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: partners.length >= 6 ? 6 : partners.length,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: partners.length >= 5 ? 5 : partners.length,
        }
      },
      {
        breakpoint: 824,
        settings: {
          slidesToShow: partners.length >= 4 ? 4 : partners.length,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: partners.length >= 3 ? 3 : partners.length,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: partners.length >= 2 ? 2 : partners.length,
        }
      }
    ]
  };

  useEffect(() => {
    axios.get(`/api/partners`)
         .then(({data}) => {
          setPartners(data);
          });
  }, []);

  if(partners.length == 0) return null;

  return (
    <div className="container partners__container" >
      <h1 className="partners__heading">{i18n.t('partners.title')}</h1>
      <Slider {...settings} className="partners__slider">
        {partners.map((partner, index) => (
          <div className="partners__item" key={index}>
            <img className="partners__logo" src={partner.logo.url} alt={partner.name}/>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Partners;