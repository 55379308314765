import React from "react";
import _ from "lodash";
import classNames from "classnames";

import * as Builders from "./builders";
import Spinner from "./../Spinner";

import "./styles.scss";

const COLOR_HIGHLIGHT = "#F8D37E";
const COLOR_DRAW = "#5B5B5B";

class VictimGrain extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) { return !_.isEqual(nextProps, this.props); }

  render() {
    const {id, onGrainClick, ...props} = this.props;
    const builders = Object.keys(Builders);
    const isRevealed = Number.isInteger(id);
    const builderID = isRevealed ? builders[id % builders.length] : builders[Math.floor((id % 1) * builders.length)];
    const Builder = Builders[builderID];

    return (
      <div className="grainy-node" role="button" onClick={(e) => onGrainClick(e, id)}>
        <Builder id={id} {...props} color={isRevealed ? COLOR_HIGHLIGHT : COLOR_DRAW} />
      </div>
    );
  }
}

export default class Grainy extends React.Component {
  touchTrack = 0;

  onIncrementView = (start = null) => {
    const viewSnapshot = (this.props.rowStart + this.props.rowsSize) * this.props.rowSize;

    if(viewSnapshot >= this.props.total + this.props.rowSize) return;

    const rowStart = start === null ? this.props.rowStart + 1 : start;

    this.props.onScroll(rowStart);
  };
  onDecrementView = () => {
    if(this.props.rowStart === 1) return;

    const rowStart = this.props.rowStart - 1;

    this.props.onScroll(rowStart);
    this.setState({rowStart});
  };
  onMouseWheel = (event) => {
    event.preventDefault();

    if(event.deltaY > 0) {
      // scrolling down;
      this.onIncrementView();
    } else if(event.deltaY < 0) {
      // scrolling up;
      this.onDecrementView();
    }
  };
  onTouchStart = (event) => {
    const e = event.originalEvent || event;

    this.touchTrack = e.changedTouches[0].clientY;
  };
  onTouchMove = (event) => {
    event.preventDefault();

    const e = event.originalEvent || event;
    const touchTrack = e.changedTouches[0].clientY;

    clearTimeout(this.touchMoveDelay);

    // emulate delay in pagination; touch move swipes too fast;
    // make it some kind of wheel scroll step;
    // other possible approach would be to calc the distance of move and scroll based on it;
    this.touchMoveDelay = setTimeout(() => {
      if (this.touchTrack > touchTrack) {
        this.onIncrementView();
      } else {
        this.onDecrementView();
      }
    }, 5);
  };
  onGrainClick = (event, seed) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.onGrainClick(seed, event.currentTarget.getBoundingClientRect());
  };

  constructor(props) {
    super(props);

    this.state = {grain: props.activeVictimID};

    this.container = React.createRef();

    this.onIncrementView = this.onIncrementView.bind(this);
    this.onDecrementView = this.onDecrementView.bind(this);
    this.onMouseWheel = this.onMouseWheel.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
  }

  componentDidMount() {
    this.container.current.addEventListener("wheel", this.onMouseWheel);
    this.container.current.addEventListener("touchstart", this.onTouchStart);
    this.container.current.addEventListener("touchmove", this.onTouchMove);
    // TODO: see how to implement IE9-IE11 support for touchmove workaround;
    // this.container.current.addEventListener("pointerup pointerdown", this.onPointerMove);
  }

  componentWillUnmount() {
    this.container.current.removeEventListener("wheel", this.onMouseWheel);
    this.container.current.removeEventListener("touchmove", this.onTouchMove);
    // TODO: see how to implement IE9-IE11 support for touchmove workaround;
    // this.container.current.removeEventListener("pointerup pointerdown", this.onPointerMove);
  }

  render() {
    const {grainWidth, grainHeight, seeds, width, height, total} = this.props;
    const nodes = _.map(seeds, (id) => (
      <VictimGrain key={id}
                   id={id}
                   width={grainWidth}
                   height={grainHeight}
                   total={total}
                   isActive={this.props.activeGrain === id}
                   autoShow={this.props.autoShowID === id}
                   onGrainClick={this.onGrainClick} />
    ));

    return(
      <div className="grainy-container">
        {this.props.loading && <div className="loading-layout"><Spinner timeout={0} /></div>}

        <div
          ref={this.container}
          className={classNames("grainy-view", {"d-none": this.props.loading})}
          style={{
            width: `${width}px`,
            maxWidth: `${width}px`,
            minWidth: `${width}px`,
            height: `${height}px`,
            maxHeight: `${height}px`,
            minHeight: `${height}px`,
          }}
          >
          {nodes}
        </div>
      </div>
    );
  }
}
