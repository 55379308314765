import React from 'react';
import { useTranslation } from "react-i18next";
import axios from '../../../packs/axios';
import ReactPaginate from 'react-paginate';
import classnames from "classnames";
import _ from "lodash";

import searchIcon from '../App/icons/search.svg';

import MetaTags from '../MetaTags';
import Spinner from '../Spinner';
import SearchItem from '../SearchPage/SearchItem';
import SearchInfo from '../SearchPage/SearchInfo'
import SearchInput from '../SearchInput';

import metaTagsText from '../../../util/meta.json'

import './search-block.scss';

const FILTER_BY_NAME = "by_name";
const FILTER_BY_LOCATION = "by_location";
const TIMEOUT = 450;
const PER_PAGE = 6;
const DEFAULT_DATA = {
  victims: [],
  totalCount: 0
};

const SearchPage = () => {
  const {i18n} = useTranslation();
  const input = React.useRef();
  const [state, setState] = React.useState({
    filter: FILTER_BY_NAME,
    settlement: '',
    region: '',
    keyword: '',
    loading: false,
    currentPage: 1,
    options: '',
    ...DEFAULT_DATA
  });
  const {options, keyword, filter, settlement, region, loading, currentPage} = state;
  const totalPages = React.useMemo(() => Math.ceil(state.totalCount / PER_PAGE), [state.totalCount]);

  const onClickReset = React.useCallback(() => {
    setState((state) => ({ ...state, settlement: '', region: '', currentPage: 1, options: "", keyword: '', ...DEFAULT_DATA}));

    input.current.setState({keyword: ''});
    input.current.focus();
  }, [setState]);
  const onSubmitSearch = React.useCallback((e) => {
    if (e) {
      e.preventDefault();
      const newKeyword = e.target[0].value;
      setState((state) => ({ ...state, keyword: newKeyword, loading: true, currentPage: 1, ...DEFAULT_DATA}));
      input.current.blur();
    }
  }, [setState]);
  const onChangePage = React.useCallback(({selected}) => {
    setState((state) => ({...state, loading: true, currentPage: selected + 1}));
  }, [setState]);
  const onBlur = React.useCallback((e) => {
    if (e.target.value) {
      const newKeyword = e.target.value;
      setState((state) => ({ ...state, keyword: newKeyword, loading: true, currentPage: 1, ...DEFAULT_DATA}));
    }
  }, [setState]);
  const onChangeFilterType = React.useCallback((new_filter) => {
    input.current.setState({keyword: ''});
    setState((state) => ({...state, settlement: '', region: '', currentPage: 1, options: "", filter: new_filter, keyword: '', ...DEFAULT_DATA}));
  }, [setState]);
  const onChangeSettlement = React.useCallback((event) => {
    const new_settlement = event.target.value;
    setState((state) => ({...state, settlement: new_settlement, currentPage: 1, loading: true, ...DEFAULT_DATA}));
  }, [setState]);
  const onChangeRegion = React.useCallback((event) => {
    const new_region = event.target.value;
    setState((state) => ({...state, region: new_region, settlement: '', currentPage: 1, loading: true, ...DEFAULT_DATA}));
  }, [setState]);
  const fetchData = React.useCallback(_.debounce((params) => {
    axios.get("/api/search.json", {params}).then(({data}) => {
      setState((state) => ({...state, ...data, loading: false, currentPage: params.page}));
    }).catch(() => {
      setState((state) => ({...state, loading: false}));
    });
  }, TIMEOUT), [setState]);

  // Reload data list;
  React.useEffect(() => {
    if(loading) {
      const params = {
        query: keyword,
        page: currentPage,
        filter_settings: {filter, location: {settlement, region}}
      };

      fetchData(params);
    }
  }, [loading, keyword, currentPage, filter, settlement, region]);

  React.useEffect(() => {
    input.current.focus();
  }, []);

  return(
    <div className="container d-flex search-wrapper flex-1 h-auto">
      <MetaTags title={metaTagsText[i18n.language].search.title} />

      <div className="row flex-1 justify-content-center align-content-between">
        <div className="col-lg-10 col-12">

          <div className="search-panel">
            <form className="search-form" onSubmit={onSubmitSearch} id='victims-search-form'>
              <div className='search-form--input col-md-10 col-12'>
                <div className="d-md-flex justify-content-center search-icon d-none d-md-flex" onClick={onSubmitSearch} role="button">
                  <img alt="Search" src={searchIcon} />
                </div>

                <SearchInput ref={input}
                             value={keyword}
                             placeholder={i18n.t("searchBlock.placeholder")}
                             onBlur={onBlur}
                             onClickReset={onClickReset}/>
              </div>
              <button type="submit" disabled={loading} className="custom-btn search-button col-2 d-none d-md-block">{i18n.t("searchBlock.search")}</button>
            </form>

            <div className="search-filters d-flex justify-content-between">
              <div className="main-filter d-flex align-items-center">
                <div className="search-title">
                  {i18n.t("searchBlock.title")}
                </div>
                <div className="switch-filters d-flex ">
                  <div role="button"
                       className={classnames("name-filter switch-btn", {'active': filter === FILTER_BY_NAME})}
                       onClick={() => onChangeFilterType(FILTER_BY_NAME)}>
                    {i18n.t("searchBlock.bySurname")}
                  </div>
                  <div role="button"
                       className={classnames("location-filter switch-btn", {'active': filter === FILTER_BY_LOCATION})}
                       onClick={() => onChangeFilterType(FILTER_BY_LOCATION)}>
                    {i18n.t("searchBlock.bySettlement")}
                  </div>
                </div>
              </div>

              { filter == FILTER_BY_NAME && !_.isEmpty(options.region) &&
              <div className="additional-select d-flex">
                <div className="select-form-wrapper">
                <select onChange={onChangeRegion}
                        value={region}
                        className="region-select select-form">
                    <option value="">{i18n.t("searchBlock.allRegions")}</option>
                    { options && options.region.map((region, index) => {
                      return(
                        <option key={`region-${index}`} value={region}>{region}</option>
                      )
                    })}
                  </select>
                  <i className="fas fa-caret-down" />
                </div>

                { !_.isEmpty(region) &&
                  <div className="select-form-wrapper">
                    <select onChange={onChangeSettlement}
                            value={settlement}
                            className="city-select select-form">
                      <option value="">{i18n.t("searchBlock.allCities")}</option>
                      { options && !_.isEmpty(region) && options.settlement.map((city, index) => {
                        return(
                          <option key={`settlement-${index}`} value={city}>{city}</option>
                        )
                      })}
                    </select>
                    <i className="fas fa-caret-down" />
                  </div>
                }
              </div>
              }
            </div>
            <div className="row">
              <div className='d-md-none d-flex flex-row col-12'>
                <button form="victims-search-form"
                        type="submit"
                        disabled={loading}
                        className="custom-btn search-button d-flex justify-content-end d-md-none">{i18n.t("searchBlock.search")}</button>
              </div>
            </div>

            {loading && <Spinner size={30} timeout={0}/>}

            {!_.isEmpty(keyword) && 
            <React.Fragment>
              {!state.loading &&
              <div className="victims-list">
                {!_.isEmpty(state.victims) && <div className="results-title">
                  {i18n.t("searchBlock.resultsTitle")}
                </div>}

                {_.isEmpty(state.victims) &&
                <div className="no-items">
                  { filter === FILTER_BY_NAME ?
                    i18n.t("searchBlock.noVictim", {keyword: keyword})
                    :
                    i18n.t("searchBlock.noLocation", {keyword: keyword})
                  }
                </div>
                }

                {state.victims.map((victim, index) => <SearchItem key={victim.id} victim={victim} currentNumber={index + (currentPage - 1) * PER_PAGE + 1} />)}
              </div>
              }

              { totalPages > 1 &&
              <ReactPaginate
                pageCount={totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                previousLabel={currentPage == 1 ? <i className="icon-arrow-disabled" /> : <i className="icon-arrow-enabled"/>}
                nextLabel={currentPage == totalPages ? <i className="icon-arrow-disabled" /> : <i className="icon-arrow-enabled"/>}
                containerClassName="pagination align-items-center"
                pageClassName="page"
                activeClassName="active"
                onPageChange={onChangePage}
              />
              }
            </React.Fragment>
            }
          </div>
        </div>
        <SearchInfo />
      </div>
    </div>
  )
}

export default SearchPage;
