import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'

import MetaTags from '../MetaTags';
import localizedRoute from '../../../helpers/linkHelper';

import './AmbassadorRequestResultPage.scss';

import metaTagsText from '../../../util/meta.json'

const AmbassadorRequestResultPage = () => {
  const { i18n } = useTranslation();

  return (
    <div className="message-wrapper flex-1 ms-disable-flex">
      <MetaTags 
        title={metaTagsText[i18n.language].ambassador_request_result.title}
        ogTitle={metaTagsText[i18n.language].ambassador_request_result.og.title}
        description={metaTagsText[i18n.language].ambassador_request_result.description}
      />
      <h1>{i18n.t('ambassadorRequestResultPage.title')}</h1>
      <h2>{i18n.t('ambassadorRequestResultPage.subtitle')}</h2>
      <Link className="to-homepage" to={localizedRoute()}>
        {i18n.t('ambassadorRequestResultPage.toHomepage')}
      </Link>
    </div>
  );
};

export default AmbassadorRequestResultPage;
