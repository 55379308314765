import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useTranslation } from 'react-i18next';

import './Breadcrumbs.scss';

const Breadcrumbs = ({ collection }) => {
  const { i18n } = useTranslation();

  return (
    <Breadcrumb>
      {(
        collection.map((breadcrumb, i) => {
          return (
            <Breadcrumb.Item
              key={i}
              linkAs={Link}
              linkProps={{ to: `/${i18n.language}${breadcrumb.to}` }}
              active={breadcrumb.active}
            >
              {breadcrumb.text}
            </Breadcrumb.Item>
          );
        })
      )}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
