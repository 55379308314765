import React, { useState, useEffect, useReducer, useCallback } from 'react';
import axios from '../../../packs/axios';
import { useTranslation, Trans } from "react-i18next";
import ReactPlayer from 'react-player';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";

import SearchInput from '../SearchInput';
import Donation from './Donation';
import MetaTags from '../MetaTags';
import GrainsView from "./../Grainy/GrainsView";
import ProgressView from "./../Grainy/ProgressView";
import GrainTooltip from "./../Grainy/Tooltip";

import localizedRoute from "../../../helpers/linkHelper";

import skipButton from "./icon_skip2x.png";

import './seeds_page.scss';

import searchIcon from '../App/icons/search.svg';
import metaTagsText from '../../../util/meta.json';

const TOTAL_SEEDS_COUNT = process.env.TOTAL_SEEDS_COUNT
  ? parseInt(process.env.TOTAL_SEEDS_COUNT, 10)
  : 7000000;
const TOTAL_SEEDS_POINTS = 7;

const ROW_SPACE = 40;
const NODE_SPACE = 6;

const GRAIN_WIDTH = 11;
const GRAIN_HEIGHT = 20;


const GRAINY_REDUCER = (state, action) => {
  switch (action.type) {
    case "START_ROW_CHANGE":
      return { ...state, rowStart: action.rowStart };
    case "LAYOUT_CHANGE":
      return { ...state, ...action.payload };
    case "PAGE_READY":
      const activeRows = Math.ceil(state.activeCount / action.payload.rowSize);
      const midSize = Math.floor(action.payload.rowsSize / 2);
      const rowsCount = Math.ceil(state.totalCount / action.payload.rowSize);
      const rowStart =
        activeRows > midSize
          ? activeRows + midSize >= rowsCount
            ? rowsCount - action.payload.rowsSize
            : activeRows - midSize
          : state.rowStart;

      return {
        ...state,
        ...action.payload,
        loading: false,
        rowStart,
      };
    case "SEEDS_LOADED":
      const totalCount = _.get(action, "data.total_count", 0);
      const revealed = _.get(action, "data.victims", []);
      const activeCount = revealed.length;
      const unrevealed = _.times(totalCount > activeCount ? totalCount - activeCount : 0, (i) => i + Math.random());
      const seeds = [...revealed, ...unrevealed];

      return {
        ...state,
        ...action.data,
        seeds,
        activeCount,
        totalCount,
      };
    default:
      throw new Error(`Invalid processing directive "${action.type}"!`);
  }
};

const TOOLTIP_REDUCER = (state, { type, payload }) => {
  switch (type) {
    case "GRAIN_TOOLTIP_AUTO_SHOW":
      return { ...state, ...payload };
    case "GRAIN_TOOLTIP_DATA_LOADED":
      return { ...state, loading: false, ...payload };
    case "GRAIN_TOOLTIP_SHOW":
      return { ...state, loading: true, ...payload };
    case "GRAIN_TOOLTIP_CLOSE":
      return DEFAULT_TOOLTIP_STATE;
  }
};

const DEFAULT_TOOLTIP_STATE = {
  loading: false,
  nodeRect: {},
  seed: null,
  victim: {},
};

const DEFAULT_GRAINY_STATE = {
  seeds: [],
  activeCount: 0,
  totalCount: 0,
  loading: true,
  width: 1,
  height: 1,
  rowSize: 1,
  rowsSize: 1,
  rowStart: 1,
  xsScreen: false,
};

const SeedsPage = ({ headerRef }) => {
  const { i18n } = useTranslation();
  const [displayPreviewVideo, setDisplayPreviewVideo] = useState(
    localStorage.getItem("display_preview_video") != "hide"
  );

  const skipVideo = () => {
    localStorage.setItem("display_preview_video", "hide");
    setDisplayPreviewVideo(false);
    document.body.style.overflow = "auto";
    headerRef.current.classList.remove("d-none");
  };

  return (
    <>
      <MetaTags
        title={metaTagsText[i18n.language].seeds.title}
        ogTitle={metaTagsText[i18n.language].seeds.og.title}
        description={metaTagsText[i18n.language].seeds.description}
      />
      {displayPreviewVideo && window.siteSettings.previewVideoUrl ? (
        <div className="preview-video">
          <div className="hidden-layer" />
          <ReactPlayer
            url={window.siteSettings.previewVideoUrl[i18n.language]}
            playing={true}
            controls={false}
            onEnded={skipVideo}
            muted
            volume={0}
            height="100%"
            width="100%"
            className="player-wrapper"
            config={{
              youtube: {
                playerVars: { showinfo: 1, hl: i18n.language },
              },
            }}
          />
          <button onClick={skipVideo} className="skip-video">
            <img src={skipButton} alt={i18n.t("seedsPage.skipVideo")} />
          </button>
        </div>
      ) : (
        <SeedsView />
      )}
    </>
  );
};

const SeedsView = () => {
  const { i18n } = useTranslation();
  const queryParams = useLocation().search;
  const history = useHistory();
  const [state, dispatch] = useReducer(GRAINY_REDUCER, DEFAULT_GRAINY_STATE);
  const [tooltipState, tooltipDispatch] = useReducer(
    TOOLTIP_REDUCER,
    DEFAULT_TOOLTIP_STATE
  );

  const location = useLocation();
  const container = React.useRef(null);
  const {
    loading,
    seeds,
    activeCount,
    totalCount,
    width,
    height,
    rowSize,
    rowsSize,
    rowStart,
    xsScreen,
    donations_count,
  } = state;
  const offset = (rowStart - 1) * rowSize;
  const limit = rowSize * rowsSize;
  const onGrainsScroll = useCallback(
    (rowStart) => dispatch({ type: "START_ROW_CHANGE", rowStart }),
    [state.rowStart]
  );
  const onGrainClick = useCallback((seed, nodeRect) => {
    tooltipDispatch({
      type: "GRAIN_TOOLTIP_SHOW",
      payload: { seed, nodeRect },
    });

    if(seed.toString().indexOf(".") >= 0) {
      // no need to request an unknown user;
      tooltipDispatch({
        type: "GRAIN_TOOLTIP_DATA_LOADED",
        payload: { victim: {} },
      });

      return;
    }

    axios
      .get(`/api/${i18n.language}/victims/${seed}.json`)
      .then(({ data }) =>
        tooltipDispatch({ type: "GRAIN_TOOLTIP_DATA_LOADED", payload: data })
      );
  }, []);
  const onTooltipHide = useCallback(
    () => tooltipDispatch({ type: "GRAIN_TOOLTIP_CLOSE" }),
    []
  );

  const redirectToSearchPage = React.useCallback(() => {
    history.push(`/${i18n.language}/search`);
  }, [history])

  const calculateLayoutStats = React.useCallback(() => {
    const width = container.current.offsetWidth - 30; // padding of the bootstrap grid;
    const height = container.current.offsetHeight * (window.matchMedia("(max-width: 1920px) and (min-width: 1200px) and (max-height: 800px)").matches ? 0.9 : 1); // remove 10% because of 'learn more' link
    const lineHeight = 20 + ROW_SPACE;
    const rowSize = Math.floor(width / (GRAIN_WIDTH + NODE_SPACE));
    const rowsSize = Math.floor(height / lineHeight);
    const xsScreen = document.getElementById("root").clientWidth <= 1199;

    return {
      width,
      height: rowsSize * lineHeight,
      rowsSize,
      rowSize,
      xsScreen,
    }
  }, [container]);

  useEffect(() => {
    const setLayoutSettings = () => {
      const layoutStats = calculateLayoutStats();

      dispatch({
        type: "LAYOUT_CHANGE",
        payload: layoutStats,
      });
    };

    const onResizeDelayed = _.debounce(setLayoutSettings, 400);

    axios.get(`/api/seeds.json${queryParams}`).then(({ data }) => {
      if(data.active_id) tooltipDispatch({type: "GRAIN_TOOLTIP_AUTO_SHOW", payload: { grain_id: data.active_id }, });

      dispatch({ type: "SEEDS_LOADED", data });

      // This is needed to calculate GrainyView height
      // after donation column renders
      setTimeout(() => {
        const layoutStats = calculateLayoutStats();
        dispatch({ type: "PAGE_READY", payload: layoutStats });
      }, 100);
    });

    window.addEventListener("resize", onResizeDelayed);

    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("resize", onResizeDelayed);
    };
  }, [calculateLayoutStats]);

  return (
    <div className="container-fluid seeds-page d-flex flex-column flex-1">
      <div className="row justify-content-center">
        <div className="col-md-6 search-panel">
          <div className={`search-form ${window.location.href.includes('search') ? '' : 'search-form--seeds-page'}`}>
            <div className="d-flex justify-content-center search-icon disable">
              <img alt="Search" src={searchIcon} />
            </div>

            <SearchInput placeholder={i18n.t("searchBlock.placeholder")}
                          onClick={redirectToSearchPage} />
          </div>
        </div>
      </div>
      <div className="row flex-1 main-section">
        <div className="offset-sm-1 col-sm-10 offset-xl-0 col-xl-9 r-static">
          <div className="content-flex-container">
            <div className="grainy-layout d-lg-flex flex-lg-column">
              <div className="row flex-1 h-100 h-lg-auto">
                <div className="col-12 col-xl-4">
                  {!loading && (
                    <ProgressView
                      total={totalCount}
                      activeCount={activeCount}
                      height={height - ROW_SPACE}
                      rowSize={rowSize}
                      rowsSize={rowsSize}
                      rowStart={rowStart}
                      xsScreen={xsScreen}
                    />
                  )}
                </div>

                <div ref={container} className="col-12 col-xl-8 r-static">
                  <GrainsView
                    loading={loading}
                    count={donations_count || 0}
                    points={TOTAL_SEEDS_POINTS}
                    grainWidth={GRAIN_WIDTH}
                    grainHeight={GRAIN_HEIGHT}
                    total={totalCount}
                    seeds={_.slice(seeds, offset, offset + limit)}
                    width={width}
                    height={height}
                    rowSize={rowSize}
                    rowsSize={rowsSize}
                    rowStart={rowStart}
                    activeGrain={tooltipState.seed}
                    autoShowID={tooltipState.grain_id}
                    onScroll={onGrainsScroll}
                    onGrainClick={onGrainClick}
                  />
                </div>

                <div className={classNames("animated fadeIn grainy-overlay", {"d-none": tooltipState.seed === null})}
                     role="button"
                     onClick={onTooltipHide} />

                {tooltipState.seed && (
                  <GrainTooltip {...tooltipState}
                                i18n={i18n}
                                location={location}
                                onClose={() => tooltipDispatch({type: "GRAIN_TOOLTIP_CLOSE"})} />
                )}
              </div>
              <LearnMoreLink />
            </div>
          </div>
        </div>
        <div className="col-xl-3 d-flex flex-column justify-content-between donation__container">
          <Donation
            donations_count={state.donations_count}
            total_sum_uah={state.total_sum_uah}
            total_sum_usd={state.total_sum_usd}
            total_sum_eur={state.total_sum_eur}
          />
        </div>
      </div>
    </div>
  );
};

const LearnMoreLink = React.memo((props) => {
  return (
    <div className="row">
      <div className="col-12 offset-xl-4 col-xl-8">
        <div className="d-flex justify-content-center">
          <NavLink
            className="info-link"
            exact
            to={localizedRoute("/information")}
          >
            <div className="d-flex flex-column flex-md-row">
              <Trans i18nKey="infoPage.link">
                <div>More on the Holodomor victims’ names on </div>
                <div className="white-space_pre">the Grains of Truth »</div>
              </Trans>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
});

export default SeedsPage;
