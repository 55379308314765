export function formatCurrency(number, currency, locale = 'uk-UA') {
  if(isNaN(number)) return '';

  number = +number;
  number = +(number.toFixed())

  return [number.toLocaleString(locale), currency].join(' ');
}

export function percent({ collected, goal }) {
  return (collected / goal * 100).toFixed();
}
