import 'core-js/stable';
import 'regenerator-runtime/runtime';

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

import 'bootstrap';
import 'bootstrap/scss/bootstrap';

import './react';
import './flash';
