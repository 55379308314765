import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import "./bank_details.scss";

export const BankDetail = ({name, value}) => {
  const { i18n } = useTranslation();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (e) => {
    let timer = setTimeout(() => setCopied(false), 1500);
    navigator.clipboard.writeText(e);
    setCopied(true);
    if (copied) clearTimeout(timer);
  }

  return(
    <div className="bank-details__line col-12">
      <div className="row">
        <div className="head col-5">{i18n.t(`bankDetails.${name}`)}</div>
        <div className="bank-details__text text-white pl-0 col-6 text-break-all">
          {value}
        </div>
        <div className="text text-white pl-0 col-1">
        { value ? <div className="copy-btn" onClick={() => copyToClipboard(value)} data-tip={copied ? i18n.t("copied") : i18n.t("copy")}>
            { copied ? <i className="fas fa-check"/> : <i className="far fa-copy"/> }
          </div> : null}
        </div>
      </div>
    </div>
  )
}
