import React from "react";
import _ from "lodash";
import classNames from "classnames";

import "./progress-scroll-bar.scss";

const VERTICAL_POINTS = 6;
const HORIZONTAL_POINTS = 3;

class RulerPanel extends React.PureComponent {
  render() {
    const {horizontal} = this.props;
    const rules = _.times((horizontal ? HORIZONTAL_POINTS : VERTICAL_POINTS) * 10 + 1, (index) => {
      return(<div key={index} className={classNames("grainy-rule", {sep: (index % 10) === 0})} />);
    });
    const styles = horizontal ? {} : {height: `${this.props.height}px`};

    return(
      <div className={classNames("grainy-ruler", {horizontal})} style={styles}>
        {rules}
      </div>
    );
  }
}

class ActiveRuler extends React.PureComponent {
  render() {
    const {xsScreen, total, activeCount} = this.props;
    const prop = xsScreen ? "width" : "height";

    const styles = {[prop]: `${activeCount * 100 / total}%`};

    return(<div className="active-view" style={styles} />);
  }
}

class CurrentRuler extends React.PureComponent {
  render() {
    const {xsScreen, rowStart, rowsSize, rowSize, total} = this.props;
    const prop = xsScreen ? "left" : "top";
    const view = rowStart === 1 ? 0 : (rowStart - 1 + rowsSize) * rowSize;
    const currentViewing = view > total ? total : view;
    const val = currentViewing * 100 / total;

    return(
      <div className="current-view" style={{[prop]: `${val}%`}}>
        <div className="current-view__indicatior"></div>
        <span>{currentViewing}</span>
      </div>
    );
  }
}

export default class ProgressView extends React.PureComponent {
  render() {
    const {xsScreen, total, activeCount, rowStart, rowsSize, rowSize, height} = this.props;

    return(
      <div className={classNames("progress-layout", {"layout-xs": xsScreen})}>
        <div className="progress-view">
          <RulerPanel horizontal={xsScreen} height={height} />
          <ActiveRuler xsScreen={xsScreen} total={total} activeCount={activeCount} />
          <CurrentRuler xsScreen={xsScreen} total={total} rowStart={rowStart} rowsSize={rowsSize} rowSize={rowSize} />
        </div>
      </div>
    );
  }
}
