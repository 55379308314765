import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Spinner from '../Spinner';
import Map from '../App/Map';
import MetaTags from '../MetaTags';
import ContributionsList from '../ContributionsList';
import Partners from '../Partners/Partners';
import axios from '../../../packs/axios';

import './contributions_page.scss';

import metaTagsText from '../../../util/meta.json'

const ContributionsPage = () => {
  window.scrollTo(0, 0);

  const { i18n } = useTranslation();
  const [data, setData] = useState({ total_pages: 1, contributions: [] });
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData(currentPage)

    return () => setLoading(true);
  }, [i18n.language, currentPage]);

  const fetchData = (page = 1) => {
    axios.get(`/api/donations?page=${page}`)
      .then(({data}) => {
        setLoading(false);
        setData(data);
      });
  }

  return(
    <div className="contributions-page">
      <MetaTags 
        title={metaTagsText[i18n.language].contributions.title}
        ogTitle={metaTagsText[i18n.language].contributions.og.title}
        description={metaTagsText[i18n.language].contributions.description}
      />
      { loading && <Spinner timeout={2000}/> }
      { data.contributions.length > 0 && (
          <>
            <h1 className="title text-center mt-3">
              {i18n.t('contributionsPage.title')}
            </h1>
            <div className="d-flex align-items-center flex-column">
              <ContributionsList
                contributions={data.contributions}
                totalPages={data.total_pages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <Map headingClasses={classnames("mb-3 text-center", {'mt-3': data.total_pages == 1})} />
            <Partners />
          </>
        )
      }
    </div>
  );
};

export default ContributionsPage;
