import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import './modal_window.scss';

import close from './close.svg';

const ModalWindow = ({ title, widthVariant = 'md', children }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => document.body.style.overflow = 'auto';
  });

  const closeModal = (e) => {
    ReactDOM.unmountComponentAtNode(document.getElementById('modal'));
  };

  useEffect(() => {
    const closeOnEsc = (e) => {
      if(e.key !== 'Escape') return;
      
      ReactDOM.unmountComponentAtNode(document.getElementById('modal'));
    }
    document.addEventListener("keydown", closeOnEsc, false);

    return () => document.removeEventListener("keydown", closeOnEsc, false);
  }, []);

  return(
    <div className="modal-fade">
      <div className="modal-overlay" onClick={closeModal} role="button" />
      <div className={`modal--dialog modal--${widthVariant}`}>
        <div className={`modal-window`}>
          <div className="modal-window-header text-center">
            <div className="modal-window-title white-space_pre-line">
              {title}
            </div>
            <button className="close-modal" onClick={closeModal}>
              <img src={close} alt="Close modal"/>
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default ModalWindow;
