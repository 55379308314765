import React, { useRef } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from 'classnames';

import AboutPage from "../AboutPage";
import CampaignsPage from "../CampaignsPage";
import CampaignPage from "../CampaignPage";
import SeedsPage from "../SeedsPage";
import ContributionsPage from "../ContributionsPage";
import AmbassadorRequestPage from "../AmbassadorRequestPage";
import NotFoundPage from "../ErrorPages/NotFoundPage";
import AmbassadorRequestResultPage from "../AmbassadorRequestResultPage";
import PrivacyPolicy from "../PrivacyPolicy";
import InfoPage from "../InfoPage";
import SearchPage from "../SearchPage";

import Header from "./Header";
import { withFooter } from "./Footer";
import history from "../../../packs/history";

import './fonts.scss';
import './icons.scss';
import './app.scss';

const App = () => {
  const { i18n } = useTranslation();
  const headerRef = useRef(null);

  const rootHandler = React.useCallback((props) => {
    history.replace(`/${i18n.language}${props.location.search}`);

    return null;
  }, [i18n.language]);

  return (
    <Router history={history}>
      <div className="content-wrapper d-flex flex-column">
        <div className={classNames({"d-none": (localStorage.getItem('display_preview_video') != 'hide' && /^(\/(en|uk)?\/?)$/g.test(window.location.pathname))})} ref={headerRef}>
          <Header/>
        </div>
        <div className="content flex-1 ms-disable-flex">
          <Switch>
            <Route exact path="/" render={rootHandler} />
            <Route exact path='/:locale(uk|en)/about' render={withFooter(AboutPage)} />
            <Route exact path='/:locale(uk|en)/information' render={withFooter(InfoPage)} />
            <Route exact path='/:locale(uk|en)/campaigns' render={withFooter(CampaignsPage)} />
            <Route exact path='/:locale(uk|en)/campaigns/:slug'render={withFooter(CampaignPage)} />
            <Route exact path='/:locale(uk|en)/contributions'render={withFooter(ContributionsPage)} />
            <Route exact path='/:locale(uk|en)/search' component={SearchPage} />
            <Route exact path='/:locale(uk|en)/ambassador_requests/new' render={withFooter(AmbassadorRequestPage)} />
            <Route exact path='/:locale(uk|en)'>
              <SeedsPage headerRef={headerRef}/>
            </Route>
            <Route exact path='/:locale(uk|en)/privacy-policy'
                   render={withFooter(PrivacyPolicy)} />
            <Route exact path='/:locale(uk|en)/thank-you'
                   render={withFooter(AmbassadorRequestResultPage)} />
            <Route exact path='*' render={withFooter(NotFoundPage)} />
          </Switch>
        </div>
      </div>
      <div id="modal" />
      <div id="toast" />
    </Router>
  );
}

export default App;
