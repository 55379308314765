import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from "react-i18next";
import axios from '../../../packs/axios';
import ReCAPTCHA from "react-google-recaptcha";
import classNames from 'classnames';

import Breadcrumbs from '../Breadcrumbs';
import SubmitButton from '../SubmitButton';

import MetaTags from '../MetaTags';

import localizedRoute from '../../../helpers/linkHelper';

import metaTagsText from '../../../util/meta.json';

import './AmbassadorRequestPage.scss';

const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;

const AmbassadorRequestBreadcrumbs = () => {
  const { i18n } = useTranslation();

  const collection = [
    { text: i18n.t("breadcrumbs.main"), to: '/' },
    { text: i18n.t("breadcrumbs.campaigns"), to: '/campaigns' },
    { text: i18n.t("breadcrumbs.ambassadorRequest"), active: true }
  ];

  return (
    <div className="campaigns--breadcrumbs">
      <Breadcrumbs collection={collection} />
    </div>
  );
};

const AmbassadorRequestPage = () => {
  const { i18n } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const recaptchaRef = React.useRef();
  const [recaptchaFailed, setRecaptchaFailed] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const onSubmit = async ambassador_request => {
    setSubmitting(true);
    setRecaptchaFailed(false);

    const formData = new FormData();
    const recaptchaToken = await recaptchaRef.current.executeAsync();

    [
      'full_name_uk', 'campaign_name_uk', 'about_campaign_uk',
      'email', 'phone', 'about_yourself_uk', 'social_profile',
      'receive_news', 'privacy_policy'
    ].forEach((field) => {
      formData.append(`ambassador_request[${field}]`, ambassador_request[field]);
    });
    formData.append('ambassador_request[photo]', ambassador_request.photo[0]);
    formData.append('recaptcha_token', recaptchaToken);

    await axios.post('/api/ambassador_requests', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(() => {
      window.location.pathname = `/${i18n.language}/thank-you`;
    })
    .catch((error) => {
      if (error.response.status === 403) setRecaptchaFailed(true);
    });
  };

  return (
    <div className="container">
      <MetaTags 
        title={metaTagsText[i18n.language].ambassador_request.title}
        ogTitle={metaTagsText[i18n.language].ambassador_request.og.title}
        description={metaTagsText[i18n.language].ambassador_request.description}
      />
      <AmbassadorRequestBreadcrumbs />
      <div className="row">
        <div className="col-12 col-lg-6 offset-lg-3">
          <form className="custom-form ambassador-request--form" onSubmit={handleSubmit(onSubmit)}>
            <div className="ambassador-request__title white-space_pre-line">
              {i18n.t("ambassadorRequestPage.formTitle")}
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_SITE_KEY}
            />
            { recaptchaFailed && (
              <label className='error'>{i18n.t("errors.suspiciousBehavior")}</label>
            )}
            <div className="form-group full_name-field">
              <label htmlFor="ambassador_request_full_name">
                {i18n.t("ambassadorRequestPage.fullName")}
                <span className="required-field">&#42;</span>
              </label>
              { errors.full_name_uk && (
                <label className='error' htmlFor='full_name_uk'>
                  {errors.full_name_uk.message}
                </label>
              ) }
              <input
                name='full_name_uk'
                type="text"
                ref={register({
                  required: i18n.t("validationMessage.required"),
                  minLength: {
                    value: 3,
                    message: i18n.t("validationMessage.min", { length: 3 }),
                  },
                  maxLength: {
                    value: 60,
                    message: i18n.t("validationMessage.max", { length: 60 }),
                  }
                })}
                id="ambassador_request_full_name"
                autoFocus
              />
            </div>

            <div className="form-group email-field">
              <label htmlFor="ambassador_request_email">
                {i18n.t("ambassadorRequestPage.email")}
                <span className="required-field">&#42;</span>
                {" "}
                {i18n.t("ambassadorRequestPage.isNotPublic")}
              </label>
              { errors.email && (
                <label className='error' htmlFor="email">
                  {errors.email.message}
                </label>
              )}
              <input
                name="email"
                type="text"
                ref={register({
                  required: i18n.t("validationMessage.required"),
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: i18n.t("validationMessage.email"),
                  },
                  maxLength: {
                    value: 255,
                    message: i18n.t("validationMessage.max", { length: 255 }),
                  }
                })}
                id="ambassador_request_email"
              />
            </div>

            <div className="form-group phone-field">
              <label htmlFor="ambassador_request_phone">
                {i18n.t("ambassadorRequestPage.phone")}
                {" "}
                {i18n.t("ambassadorRequestPage.isNotPublic")}
              </label>
              { errors.phone && (
                <label className='error' htmlFor="ambassador_request_phone">
                  {errors.phone.message}
                </label>
              )}
              <input
                name="phone"
                type="text"
                ref={register({
                  pattern: {
                    value: /^\+?\d+$/,
                    message: i18n.t("validationMessage.phone"),
                  }
                })}
                id="ambassador_request_phone"
              />
            </div>

            <div className="form-group social_profile-field">
              <label htmlFor="ambassador_request_social_profile">
                {i18n.t("ambassadorRequestPage.socialLink")}
              </label>
              <input
                name="social_profile"
                type="text"
                ref={register}
                id="ambassador_request_social_profile"
              />
            </div>

            <div className="form-group about_yourself-field">
              <label htmlFor="ambassador_request_about_yourself">
                { i18n.t('ambassadorRequestPage.aboutYourself') }
                <span className="required-field">&#42;</span>
              </label>
              { errors.about_yourself_uk && (
                  <label className='error' htmlFor="ambassador_request_about_yourself">
                    {errors.about_yourself_uk.message}
                  </label>
                )
              }
              <textarea
                name='about_yourself_uk'
                rows="10"
                ref={register({
                  required: i18n.t("validationMessage.required"),
                  minLength: {
                    value: 10,
                    message: i18n.t("validationMessage.min", { length: 10 }),
                  }
                })}
                id="ambassador_request_about_yourself"
              />
            </div>

            <div className="form-group campaign_name-field">
              <label htmlFor="ambassador_request_campaign_name">
                {i18n.t("ambassadorRequestPage.campaignName")}
                <span className="required-field">&#42;</span>
              </label>
              { errors.campaign_name_uk && (
                  <label className='error' htmlFor="campaign_name_yourself">
                    {errors.campaign_name_uk.message}
                  </label>
                )
              }
              <input
                name="campaign_name_uk"
                type="text"
                ref={register({
                  required: i18n.t("validationMessage.required")
                })}
                id="ambassador_request_campaign_name"
              />
            </div>

            <div className="form-group about_campaign-field">
              <label htmlFor="ambassador_request_about_campaign">
                { i18n.t('ambassadorRequestPage.aboutCampaign') }
                <span className="required-field">&#42;</span>
              </label>
              { errors.about_campaign_uk && (
                  <label className='error' htmlFor="campaign_name_yourself">
                    {errors.about_campaign_uk.message}
                  </label>
                )
              }
              <textarea
                name='about_campaign_uk'
                rows="10"
                ref={register({
                  required: i18n.t("validationMessage.required")
                })}
                id="ambassador_request_about_campaign"
              />
            </div>

            <div className="form-group picture-field">
              <div className="d-flex flex-column">
                <div>
                  <label htmlFor="ambassador_request_picture">
                    { i18n.t('ambassadorRequestPage.campaignLogo') }
                  </label>
                  { errors.photo && (
                    <label className='error' htmlFor="campaign_name_yourself">
                      {errors.photo.message}
                    </label>
                  )}
                </div>
                <div className="d-flex">
                  <input
                    name="photo"
                    type="file"
                    ref={register({
                      validate: {
                        format: ([ file ]) => {
                          if (!file) return null;

                          const allowedFormats = ['jpg', 'jpeg', 'png'];
                          const regex = new RegExp(`\.(${allowedFormats.join('|')})$`);

                          return regex.test(file.name) || i18n.t("validationMessage.extension", { formats: allowedFormats.join(', ') })
                        },
                        size: ([ file ]) => {
                          if (!file) return null;

                          const maxFileSizeMB = 10;
                          const maxSizeInBytes = maxFileSizeMB * 1024 * 1024;

                          return file.size <= maxSizeInBytes || i18n.t("validationMessage.fileSize", { size: maxFileSizeMB })
                        }
                      }
                    })}
                    id="ambassador_request_photo"
                  />
                </div>
              </div>
            </div>

            <div className="form-group receive-news with-checkbox ambassador-request__checkbox-container">
              <input type="checkbox" name="receive_news" id="receive_news" ref={register} />
              <label htmlFor="receive_news">{i18n.t("donationForm.receiveNotifications")}</label>
            </div>

            <div className="form-group with-checkbox ambassador-request__checkbox-container">
              <input type="checkbox" name="privacy_policy" id="privacy_policy" className={classNames({"privacy_policy--error": errors.privacy_policy})} ref={register({required: true})} />
              <label htmlFor="privacy_policy">
                <Trans i18nKey="donationForm.privacyPolicy">
                    I accept <a href={localizedRoute("/privacy-policy")} target="_blank">privacy policy</a>.
                </Trans>
                <span className="required-field">&#42;&nbsp;</span>
              </label>
            </div>

            <div className="form-group submit-button d-flex justify-content-center">
              <SubmitButton
                loading={submitting}
                classNames="custom-btn custom-btn-yellow"
              >
                { i18n.t("ambassadorRequestPage.send")}
              </SubmitButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AmbassadorRequestPage;
