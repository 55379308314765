import React from 'react';

const SubmitButton = ({ loading, classNames, children }) => {
  return (
    <button type="submit" disabled={loading} className={classNames}>
      { loading && (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}

      { !loading && children }
    </button>
  );
};

export default SubmitButton;
