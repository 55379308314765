import React from 'react';
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom';

import BankDetails from '../SeedsPage/BankDetails';
import ModalWindow from '../ModalWindow';

import './search-block.scss';

const SearchItem = (props) => {
  const { i18n } = useTranslation();
  const { fullName, info, settlement, region, district, note, id } = props.victim;
  const { currentNumber } = props;

  const showDonateModal = () => {
    ReactDOM.render(
      <ModalWindow title={i18n.t(`seedsPage.contribute`)}>
        <BankDetails/>
      </ModalWindow>, document.getElementById('modal')
    );
  };

  return(
    <div className="search-item d-flex">
      <div className="current-number">
        {`${currentNumber}.`}
      </div>

      <div className='d-flex victim-block align-items-start'>
        <div className="victim-info d-flex flex-column">
          <div className="victim-name">{fullName}</div>
          <div className="victim-detailed d-flex">
            <div className="victim-region info-row">
              {i18n.t("searchBlock.location", {settlement: settlement, district: district, region: region})}
            </div>
            { !_.isEmpty(info) &&
              <div className="victim-info info-row">
                {i18n.t("searchBlock.info", {info: info})}
              </div>
            }
            { !_.isEmpty(note) &&
              <div className="victim-note info-row">
                {i18n.t("searchBlock.note", {note: note})}
              </div>
            }
          </div>
        </div>
        <div className='donation-btn font-weight-bold' role="button" onClick={showDonateModal}>
          {i18n.t("searchBlock.addDonation")}
        </div>
      </div>
    </div>
  )
};

export default SearchItem;
